import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import { useState } from "react";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { PopupModal } from "../../../components/PopupModal";
import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import { AdminUser, UpdateAdminUserDto } from '../AdminUserList.types';
import { ConfirmDeleteAdminUser } from "./ConfirmDeleteAdminUser";
import { PermissionsGrid } from "./PermissionsGrid";

export function AdminUserModal({ adminUser, onClose }: { adminUser?: AdminUser; onClose: () => void }) {
  const [form, setForm] = useState<AdminUser>(adminUser ? adminUser : {
    firstName: '',
    lastName: '',
    email: '',
    permissions: [],
  });

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [changeMade, setChangeMade] = useState(false)

  const [deleteUser, setDeleteUser] = useState<AdminUser>();
  const isNewRequest = !adminUser?.id
  const [editMode, setEditMode] = useState(isNewRequest);

  const handleDeleteUser = async () => {
    if (adminUser) {
      setDeleteUser(adminUser)
    }
  }

  const handleSubmit = async () => {
    let newErrors = {}

    if (!form.firstName) {
      newErrors = { ...newErrors, clientName: "Please select a valid first name" }
    }

    if (!form.lastName) {
      newErrors = { ...newErrors, clientName: "Please select a valid last name" }
    }

    if (!form.email) {
      newErrors = { ...newErrors, clientName: "Please select a valid email" }
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors)
      return
    }

    setErrors({})
    setLoading(true)

    if (isNewRequest) {
      try {
        const response = await api.createAdminUser(form)
        if ([200, 201].includes(response.status)) {
          ShowToast({
            message: `You've successfully created the new admin user for ${form.firstName}`,
            type: "success",
          });
          setSubmitSuccess(response.data.id)
        }
      } catch (error: any) {
        console.log(error)
        ShowToast({
          message: error?.response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
    } else {

      const updateDto: UpdateAdminUserDto = {
        id: form.id!,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        permissions: form.permissions
      }
      const response = await api.updateAdminUser(adminUser.id!, updateDto)
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: `You've successfully updated the admin user (${form.firstName})`,
          type: "success",
        });
        onClose()
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
    }
    setLoading(false)
  };

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const disabled = !form.firstName || !form.lastName || !form.email || !isValidEmail(form.email) || loading;

  if (submitSuccess) {
    return <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[600px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div id="provider-scroll" className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            Admin User {isNewRequest ? 'Created' : 'Updated'}
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full text-center">
          <SuccessIcon className="w-64 h-auto max-h-96 mx-auto" />
        </div>
        {`You've successfully created the new admin user for ${form.firstName}. An email has been sent to ${form.email} with their login details.`}
      </>
    </PopupModal >
  }

  const disableForm = !isNewRequest;

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            {isNewRequest ? 'Create Admin User' : `Admin User: ${form.firstName} ${form.lastName}`}
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-155px)] overflow-auto" id="provider-scroll">

          <div className="flex flex-col mb-5">

            <div className="grid grid-cols-2 gap-10 mb-3">
              <div>
                <span className="text-black/80 text-sm">First Name</span>
                <TextInput
                  className={`h-10`}
                  name="firstName"
                  value={form.firstName}
                  onChange={(e) => {
                    setForm({ ...form, firstName: e.target.value })
                    setChangeMade(true)
                  }}
                  disabled={loading || !editMode}
                />
              </div>
              <div>
                <span className="text-black/80 text-sm">Last Name</span>
                <TextInput
                  className={`h-10`}
                  name="lastName"
                  value={form.lastName}
                  onChange={(e) => {
                    setForm({ ...form, lastName: e.target.value })
                    setChangeMade(true)
                  }}
                  disabled={loading || !editMode}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 mb-3">
              <div>
                <span className="text-black/80 text-sm">Email</span>
                <TextInput
                  className={`h-10`}
                  name="email"
                  value={form.email}
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value })
                    setChangeMade(true)
                  }}
                  disabled={loading || !editMode}
                />
              </div>
              <div className="bg-white mb-3">

              </div>
            </div>
            <div className={`bg-white`}>
              <span className="text-black/80 text-sm">
                Permissions
              </span>
              <div className="">
                <PermissionsGrid permissions={form.permissions}
                  setPermissions={(permissions) => {
                    setForm({ ...form, permissions })
                    setChangeMade(true)
                  }}
                  disabled={loading || !editMode} />
              </div>
            </div>

          </div>


          <div className="w-full">
            {isNewRequest ?
              <div className="grid grid-cols-2 gap-10 px-1">
                <Button
                  disabled={loading}
                  variant="outline"
                  className="!rounded-full w-full mt-0"
                  onClick={onClose}
                  loading={loading}
                >
                  Cancel
                </Button>
                <Button
                  disabled={disabled || loading}
                  variant="primary"
                  className="!rounded-full w-full mt-0"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Create
                </Button>
              </div>
              : editMode ?
                <div className="grid grid-cols-2 gap-10 px-1">
                  <Button
                    disabled={loading}
                    variant="outline"
                    className="!rounded-full w-full mt-0"
                    onClick={onClose}
                    loading={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={loading}
                    variant={(!changeMade) ? "outline" : "primary"}
                    className="!rounded-full w-full mt-0"
                    onClick={() => (changeMade) ? handleSubmit() : setEditMode(false)}
                    loading={loading}
                  >
                    {(changeMade) ? "Save" : "Stop Editing"}
                  </Button>
                </div>
                :
                <div className="grid grid-cols-2 gap-10 px-1">
                  <Button
                    disabled={loading}
                    variant="primary"
                    className="!rounded-full w-full mt-0 bg-red-500 !text-white !hover:bg-black"
                    onClick={handleDeleteUser}
                    loading={loading}
                  >
                    Delete User
                  </Button>
                  <Button
                    disabled={disabled || loading}
                    variant="primary"
                    className="!rounded-full w-full mt-0 hover:bg-black"
                    onClick={() => setEditMode(true)}
                    loading={loading}
                  >
                    Edit User
                  </Button>
                </div>
            }
          </div>

        </div>

        {deleteUser && <ConfirmDeleteAdminUser
          adminUser={deleteUser}
          onCancel={() => setDeleteUser(undefined)}
          onDelete={onClose}
        />}

      </>
    </PopupModal >);


}
