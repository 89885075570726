import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import GoogleButton from 'react-google-button'
import { TextInput } from "../../../components/TextInput";
import { Header } from "../components/Header";
import React, { useState } from "react";
import { api } from "../../../services/api";
import passwordValidation, {
  setToken,
  setUser as setUserInfo,
} from "../../../services/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { Password } from "../components/Password";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { useGoogleLogin } from "@react-oauth/google";
import apiClient from "../../../services/axios";

export function Login() {
  const navigate = useNavigate();
  const { fetchMyPermissions } = useAdminUsers()
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [params] = useSearchParams();
  const [isGoogleLogin, setIsGoogleLogin] = useState(!(params.get("usepassword") === 'yes'));
  const [error, setError] = React.useState<string>("");
  const [errors, setErrors] = useState({} as any);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setError("");
    setLoading(true);
    try {
      const response = await api.login({
        email: data.email,
        password: data.password,
        userType: "A",
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result?.accessToken) {
        setToken(result?.accessToken);
        if (result?.id) {
          const userResponse = await api.getUserProfile(result?.id);
          setUserInfo({ ...userResponse });
          fetchMyPermissions()
        }
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleGoogleLogin = async (response) => {
    setError("");
    setLoading(true);
    try {
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result?.accessToken) {
        setToken(result?.accessToken);
        if (result?.id) {
          const userResponse = await api.getUserProfile(result?.id);
          setUserInfo({ ...userResponse });
          fetchMyPermissions()
        }
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid Login");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);


    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password: "Invalid password",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      data.email &&
      data.password &&
      passwordValidation(data.password.toString())
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        const response = await apiClient.post('/auth/google', {
          code,
        });
        await handleGoogleLogin(response);
      } catch (error) {
      }
    },
    flow: 'auth-code',
  });

  return (
    <div
      className="h-screen bg-secondary overflow-auto"
      style={{
        backgroundImage: "url('/images/authpage-overlay.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <div className="py-10 px-4 md:px-0 h-[calc(100%-70px)] flex justify-center items-center">
        <div className="flex justify-center items-center">
          <div className="w-[442px]">
            <div className="bg-white p-8 rounded-[16px]">
              <h1 className="text-3xl font-bold">Admin Sign In</h1>

              {isGoogleLogin ? <div className="p-4 mt-5 flex justify-center">
                <GoogleButton onClick={googleLogin} />

              </div>
                :
                <form
                  className="mt-4"
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                >
                  {error && (
                    <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                      <AlertIcon /> {error}
                    </div>
                  )}
                  <div className="mb-[22px] flex flex-col gap-1.5">
                    <label htmlFor="email" className="text-sm text-black/80">
                      Email
                    </label>
                    <TextInput
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className=" flex flex-col gap-1.5">
                    <label htmlFor="password" className="text-sm text-black/80">
                      Password
                    </label>
                    <Password
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                    />
                    {errors.password && (
                      <div className="text-red-500 text-xs font-medium">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="mt-7">
                    <Button
                      type="submit"
                      variant="primary"
                      className="!rounded-full w-full"
                      disabled={disabled || loading}
                      loading={loading}
                    >
                      Login
                    </Button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
