import { Button } from "../../../components/Button";
import { PopupModal } from "../../../components/PopupModal";

export function LoginAsConfirm({
  onCancel,
  onLoginAs,
  user,
}: {
  onCancel: () => void;
  onLoginAs: () => void;
  user: any;
}) {
  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium">
          Login as {user?.firstName}{" "}{user?.lastName}<br />
          <br />
          <small>Any action taken will be recorded as if the user themselves did it. Your login as the user will be recorded for audit purposes.</small>
          <br />
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
          >
            Go Back
          </Button>
          <Button
            onClick={onLoginAs}
            className="!rounded-full w-full flex-1"
            variant="primary"
          >
            Login As {user?.firstName}
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}
