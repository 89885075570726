import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import moment from "moment";
import { DeleteRbtCandidateConfirm } from "./CancelConfirmRbtCandidate";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { PopupModal } from "../../../components/PopupModal";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { SelectInput } from "../../../components/SelectInput";
import { Button } from "../../../components/Button";
import { rbtCandidateStatusToSentenceCase, PreferredContactMethod, RbtCandidate, RbtCandidateStatus, rbtCandidateStatusColor, WorkPreference } from "../RbtCandidate.types";
import { DAY, Shift } from "../../rbtRequests/RequestRbt.types";
import { FileUploader } from "react-drag-drop-files";
import { v1 as uuidv1 } from "uuid";
import { fileUpload } from "../../../services/file-upload";
import { AddShiftModal } from "../../rbtRequests/modals/AddShiftModal";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";

export function RbtCandidateModal({ rbtCandidate, onAssignCandidate, onClose }: { rbtCandidate?: RbtCandidate; onAssignCandidate: () => void; onClose: () => void }) {
  const { myPermissions } = useAdminUsers();
  const [form, setForm] = useState<RbtCandidate>(rbtCandidate ? rbtCandidate : {
    isActive: true,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    preferredContact: PreferredContactMethod.EMAIL,
    workPreference: WorkPreference.EITHER,
    targetWeeklyHours: 0,
    scheduleAvailability: [],
    comments: '',
    resumeUrl: '',
    assignedDate: undefined,
    reviewDeadline: undefined,
    assignedRequestId: '',
    status: RbtCandidateStatus.UNASSIGNED,
  });
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showAddShift, setShowAddShift] = useState(false);
  const [editMode, setEditMode] = useState(!rbtCandidate)
  const [submitSuccess, setSubmitSuccess] = useState<string | undefined>()
  const [deleteRequestId, setDeleteRequest] = useState<RbtCandidate | undefined>()
  const [changeMade, setChangeMade] = useState(false)

  const isNewCandidate = !rbtCandidate

  const prettyTime = (date: Date | string) => {
    const d = new Date(date)
    const mins = d.getMinutes() < 10 ? `${d.getMinutes()}0` : d.getMinutes()
    if (isNaN(d.getMinutes())) return date;
    return `${d.getHours()}:${mins}`
  }

  React.useEffect(() => {
    // check for scroll event and set scroll value in state
    const onScroll = (e) => {
      setIsScrolled(e.target.scrollTop > 200);
    };
    const element = document.getElementById("provider-scroll");
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, []);


  const handleCancel = async () => {
    if (rbtCandidate?.id) {
      setDeleteRequest(rbtCandidate)
    }
  }

  const handleSubmit = async () => {
    let newErrors = {}
    if (!form.firstName || form.firstName.trim().length === 0) {
      newErrors = { ...newErrors, firstName: "Please provide candidate's First Name" }
    }

    if (!form.lastName || form.lastName.trim().length === 0) {
      newErrors = { ...newErrors, lastName: "Please provide candidate's Last Name" }
    }

    if (form.preferredContact === PreferredContactMethod.EMAIL && (!form.email || form.email.trim().length === 0)) {
      newErrors = { ...newErrors, email: `Please provide valid email for candidate` }
    }

    if (form.preferredContact === PreferredContactMethod.PHONE && (!form.mobile || form.mobile.trim().length === 0)) {
      newErrors = { ...newErrors, mobile: `Please provide valid phone number for candidate` }
    }

    if (form.preferredContact === PreferredContactMethod.OTHER && (!form.email || form.email.trim().length === 0) && (!form.mobile || form.mobile.trim().length === 0)) {
      newErrors = { ...newErrors, email: `Please provide valid email (or phone number) for candidate` }
      newErrors = { ...newErrors, mobile: `Please provide valid phone number (or email) for candidate` }
    }

    if (form.zipCode && !isZipValid(form.zipCode)) {
      newErrors = { ...newErrors, zipCode: "Please provide either a valid zip" }
    }

    if (!form.workPreference) {
      newErrors = { ...newErrors, workPreference: "Please provide work preference" }
    }

    if (form.scheduleAvailability.length === 0) {
      newErrors = { ...newErrors, scheduleAvailability: "Please provide availability" }
    }

    if (!form.targetWeeklyHours || form.targetWeeklyHours <= 0 || form.targetWeeklyHours > 40) {
      newErrors = { ...newErrors, targetWeeklyHours: "Please provide weekly target hours" }
    }

    if ((form?.comments?.length ?? 0) > 1000) {
      newErrors = { ...newErrors, comments: `Please keep additional comments under 1000 characters (currently ${form.comments?.length})` }
    }

    // require resume url
    if (!form.resumeUrl) {
      newErrors = { ...newErrors, resumeUrl: "Please upload candidate's resume" }
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors)
      return
    }

    setErrors({})
    setLoading(true)

    if (isNewCandidate) {
      const response = await api.createRbtCandidate(form)
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: "You've successfully created a RBT Candidate",
          type: "success",
        });
        setSubmitSuccess(`${response.data.firstName} ${response.data.lastName}`)
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
    } else {
      const response = await api.updateRbtCandidate(rbtCandidate?.id, form)
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: `You've successfully updated RBT Candidate`,
          type: "success",
        });
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
      onClose();
    }
    setLoading(false)

  };

  const disabled = false;

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState] = useMemo(() => {
    return (form.zipCode && isZipValid(form.zipCode)) ? [zipCodes[form.zipCode]?.city, zipCodes[form.zipCode]?.state_short, zipCodes[form.zipCode]?.state] : [undefined, undefined];
  }, [form.zipCode]);


  if (submitSuccess) {
    return <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[642px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div id="provider-scroll" className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            RBT Candidate Form
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full text-center">
          <SuccessIcon className="w-64 h-auto max-h-96 mx-auto" />
          <p className="text-xl mb-2">
            Candidate {submitSuccess} Created.
          </p>
        </div>
      </>
    </PopupModal >
  }

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            Candidate Profile {!isNewCandidate && `(${rbtCandidate?.firstName} ${rbtCandidate?.lastName})`}
            {!isNewCandidate && rbtCandidate.status &&
              <>
                <div className="inline-block ml-5 px-2 h-5 rounded-lg gap-2.5" style={{ transform: "translateY(-3px)", backgroundColor: rbtCandidateStatusColor(rbtCandidate.status) }}>
                  <div className="text-white text-sm font-normal">{rbtCandidateStatusToSentenceCase(rbtCandidate.status)}</div>
                </div>
                <div className="inline-block font-normal text-base text-primary cursor-pointer ml-10"
                  onClick={onAssignCandidate}>Assign Candidate</div>
              </>}

          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-155px)] overflow-auto" id="provider-scroll">
          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">First Name</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="firstName"
                value={form.firstName}
                onChange={(e) => {
                  setForm({ ...form, firstName: e.target.value })
                  setChangeMade(true)
                }}
                disabled={!editMode || loading}
              />
              {errors.firstName && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.firstName}
                </div>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Last Name</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="lastName"
                value={form.lastName}
                onChange={(e) => {
                  setForm({ ...form, lastName: e.target.value })
                  setChangeMade(true)
                }}
                disabled={!editMode || loading}
              />
              {errors.lastName && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.lastName}
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Email</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="email"
                value={form.email}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value })
                  setChangeMade(true)
                }}
                disabled={!editMode || loading}
              />
              {errors.email && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Phone</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="mobile"
                value={form.mobile}
                onChange={(e) => {
                  setForm({ ...form, mobile: e.target.value })
                  setChangeMade(true)
                }}
                disabled={!editMode || loading}
              />
              {errors.mobile && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.mobile}
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mb-5" style={zipCity ? { marginTop: '-20px' } : { marginTop: '0px' }}>
            <div className={`flex-1 w-full flex flex-col gap-1.5 ${zipCity ? 'mt-5' : ''}`}>
              <label className="text-sm text-black/80">
                Zip Code
              </label>
              <TextInput
                className={`h-16 ${!editMode && 'text-gray-400'}`}
                name="zipCode"
                value={form.zipCode}
                onChange={(e) => {
                  setForm({ ...form, zipCode: e.target.value })
                  setChangeMade(true)
                }}
                placeholder={editMode ? "Enter Zip Code" : "-"}
                disabled={!editMode || loading}
              />
              {zipCity && <div className="text-xs">City: {zipCity}, {zipState}</div>}
              {errors.zipCode && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.zipCode}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-10 px-1">
              <div className="mb-3">
                <span className="text-black/80 text-sm">Preferred Method of Contact</span>
                <SelectInput
                  data={[
                    { value: "EMAIL", label: "Email" },
                    { value: "PHONE", label: "Phone" },
                    { value: "OTHER", label: "Other" }
                  ]}
                  name="preferredContact"
                  value={form.preferredContact}
                  disabledOptionText={"Select Preferred Method of Contact"}
                  onChange={(e) => {
                    setForm({ ...form, preferredContact: e.target.value as PreferredContactMethod });
                    setChangeMade(true)
                  }}
                  className={`h-16 ${!editMode && 'text-gray-400'}`}
                  disabled={!editMode || loading}
                />
                {errors.preferredContact && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.preferredContact}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <span className="text-black/80 text-sm">Work Preference</span>
                <SelectInput
                  data={[
                    { value: "FULLTIME", label: "Full Time" },
                    { value: "PARTTIME", label: "Part Time" },
                    { value: "EITHER", label: "Either" }
                  ]}
                  name="workPreference"
                  value={form.workPreference}
                  disabledOptionText={"Select Work Preference"}
                  onChange={(e) => {
                    setForm({ ...form, workPreference: e.target.value as WorkPreference });
                    setChangeMade(true)
                  }}
                  className={`h-16 ${!editMode && 'text-gray-400'}`}
                  disabled={!editMode || loading}
                />
                {errors.workPreference && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.workPreference}
                  </div>
                )}
              </div>
            </div>


            <div className="grid grid-cols-2 gap-10 px-1">

              <div className="flex flex-col mb-5">
                <span className="text-black/80 text-sm">Schedule Availability</span>
                <div className={`border rounded-lg min-h-[155px] p-2 relative ${(!editMode || loading) && 'text-gray-400'}`} style={(editMode || !loading) ? {} : { "backgroundColor": "rgba(239, 239, 239, 0.3)" }}>
                  {form.scheduleAvailability.map((shift, i) =>
                  (<div key={i} className="text-bold">{`${shift.day}: ${prettyTime(shift.startTime)} - ${prettyTime(shift.endTime)}`}
                    {shift.timeZone && ` (${DateTime.local().setZone(shift.timeZone).offsetNameShort})`}
                    {(editMode && !loading) && <div className="inline-block text-sm text-red-600 cursor-pointer ml-1" onClick={() => {
                      setForm({
                        ...form, scheduleAvailability: form.scheduleAvailability.length === 1 ? [] : form.scheduleAvailability.splice(i, 1) && form.scheduleAvailability
                      });
                      setChangeMade(true)
                    }}
                    > (Remove)</div>}
                  </div>)
                  )}
                  {form.scheduleAvailability.length === 0 && <div>No sessions added</div>}
                  {showAddShift && <AddShiftModal title="Add RBT Schedule Availability" onClose={() => setShowAddShift(false)} onAdd={(shifts: Array<Shift>) => {
                    setForm({
                      ...form, scheduleAvailability:
                        [...form.scheduleAvailability, ...shifts].sort((a, b) => {
                          if (a.day === b.day) {
                            return (new Date(a.startTime)).getTime() - (new Date(b.startTime)).getTime()
                          }
                          return [...Object.keys(DAY)].indexOf(a.day) - [...Object.keys(DAY)].indexOf(b.day)
                        })
                    })
                    setChangeMade(true)
                    setErrors({ ...errors, scheduleAvailability: undefined })
                  }} />}
                  {(editMode && !loading) && <div className="text-primary cursor-pointer absolute bottom-2 right-3" onClick={() => setShowAddShift(true)}>Add Availability</div>}
                </div>
                {errors.scheduleAvailability && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.scheduleAvailability}
                  </div>
                )}
              </div>

              <div>
                <div className="flex flex-col mb-3">
                  <span className="text-black/80 text-sm">Target Weekly Hours</span>
                  <TextInput
                    type="number"
                    className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                    name="clientZip"
                    value={form.targetWeeklyHours}
                    onChange={(e) => {
                      if (e.target.value === "") setForm({ ...form, targetWeeklyHours: '' })
                      const num = parseInt(e.target.value)
                      if (!isNaN(num) && num > 0 && num <= 40) {
                        setForm({ ...form, targetWeeklyHours: num })
                        setChangeMade(true)
                      }
                    }
                    }
                    disabled={!editMode || loading}
                  />
                  {errors.targetWeeklyHours && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.targetWeeklyHours}
                    </div>
                  )}
                </div>


                <div className="mb-5">
                  <span className="text-black/80 text-sm block">Resume Upload</span>
                  {form.resumeUrl ?
                    <div className="inline-block">
                      <a target="_blank" href={form.resumeUrl} rel="noreferrer"><div className="text-primary cursor-pointer inline-block">
                        {decodeURIComponent(form.resumeUrl.substring(form.resumeUrl.lastIndexOf('/') + 1))}
                      </div></a>

                      {(editMode && !loading) && <div className="inline-block text-sm text-red-600 cursor-pointer ml-1" onClick={() => {
                        setForm({
                          ...form, resumeUrl: undefined
                        });
                        setChangeMade(true)
                      }}
                      > (Remove)</div>}
                    </div>
                    :
                    <FileUploader
                      disabled={loading || !editMode}
                      handleChange={async (file) => {
                        setLoading(true)
                        const fileName = file.name.lastIndexOf('.') !== -1 ? file.name.substring(0, file.name.lastIndexOf('.')) : file.name
                        const upload = await fileUpload(file, uuidv1(), "RESUME", fileName)
                        if (upload.url) {
                          setForm({ ...form, resumeUrl: upload.url })
                          setChangeMade(true)
                        }
                        setLoading(false)
                      }} name="file" types={["png", "jpeg", "jpg", "pdf"]}>
                      <div className="flex flex-col cursor-pointer items-center h-[65px] gap-2 bg-[rgba(196,243,229,0.40)] p-[18px] border border-dashed border-primary rounded-[11px]">
                        <div className="bg-white flex justify-center items-center rounded-full w-[56px] h-[56px]">
                          <UploadIcon />
                        </div>
                      </div>

                    </FileUploader>
                  }
                  {errors.resumeUrl && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.resumeUrl}
                    </div>
                  )}
                </div>

              </div>

            </div>
          </div>



          <div className="flex flex-col mb-2">
            <span className="text-black/80 text-sm">Comments</span>
            <TextArea
              className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
              name="comments"
              rows={3}
              value={form.comments}
              onChange={(e) => {
                setForm({ ...form, comments: e.target.value });
                setChangeMade(true)
              }}
              disabled={!editMode || loading}
            />
            {errors.comments && (
              <div className="text-red-500 text-xs font-medium">
                {errors.comments}
              </div>
            )}
          </div>

        </div>

        {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_RBT) &&
          <div className="w-full">
            {isNewCandidate ?
              <Button
                disabled={disabled || loading}
                variant="primary"
                className="!rounded-full w-full mt-0"
                onClick={handleSubmit}
                loading={loading}
              >
                Create Candidate
              </Button>
              :
              <div className="grid grid-cols-2 gap-10 px-1">
                <Button
                  disabled={disabled || loading || editMode}
                  variant="primary"
                  className="!rounded-full w-full mt-0 bg-red-500 text-white"
                  onClick={handleCancel}
                  loading={loading}
                >
                  Delete Candidate
                </Button>
                <Button
                  disabled={disabled || loading}
                  variant={(editMode && !changeMade) ? "outline" : "primary"}
                  className="!rounded-full w-full mt-0"
                  onClick={() => editMode ? ((changeMade) ? handleSubmit() : setEditMode(false)) : setEditMode(!editMode)}
                  loading={loading}
                >
                  {editMode ? ((changeMade) ? "Save" : "Stop Editing") : "Edit Candidate"}
                </Button>
              </div>
            }
          </div>
        }



        {deleteRequestId && <DeleteRbtCandidateConfirm
          onCancel={() => setDeleteRequest(undefined)}
          onDelete={onClose}
          candidate={deleteRequestId} />}

      </>
    </PopupModal >
  );
}
