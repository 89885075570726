import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "../../../components/Button";
import { useAdminUsers } from "../context/AdminUsersContext";
import { AdminUser, FULL_PERMISSIONS } from "../AdminUserList.types";
import { AdminUserModal } from "../modals/AdminUserModal";


export function AdminUserList({ onClose, onRefresh }: { onClose?: () => void, onRefresh?: () => void }) {
  const [selectedAdminUser, setSelectedAdminUser] = useState<AdminUser>();
  const { adminUsers, refresh: refreshAdminUsers } = useAdminUsers();
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    refreshAdminUsers()
  }, [])

  const sortedAdminUsers = adminUsers.sort((a, b) => {

    // default
    if (!sortField && a.createdAt && b.createdAt) {
      return a.createdAt < b.createdAt ? 1 : -1;
    }

    if (sortField === 'name' && a?.firstName && b?.firstName) {
      const aName = `${a.firstName} ${a.lastName}`;
      const bName = `${b.firstName} ${b.lastName}`;
      return aName.localeCompare(bName) * (sortASC ? 1 : -1);
    }

    if (sortField === 'email' && a?.email && b?.email) {
      return a?.email.localeCompare(b?.email) * (sortASC ? 1 : -1);
    }

    if (sortField === 'permissions' && a?.permissions && b?.permissions) {
      return (a?.permissions.length - b?.permissions.length) * (sortASC ? 1 : -1);
    }

    if ((sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }

    return 0;

  }).filter(request => {
    if (!searchFilter || searchFilter.length === 0) return true;
    if (`${request.firstName} ${request?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (`${request?.firstName} ${request?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (request?.email?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    return false;
  })

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortField(undefined)
        setSortASC(true)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "name", label: "Admin Name" },
    { value: "email", label: "Email" },
    { value: "permissions", label: "Permissions" },
    { value: "createdAt", label: "Created Date" },

  ]

  return <LoadAnimatedContainer>
    <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
      <div className="text-black text-3xl font-bold">Manage Admin Users</div>
      <div className="flex justify-center"></div>
    </div>
    <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
            <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
            <input
              type="text"
              placeholder="Search"
              className="outline-none"
              onChange={(e) => setSearchFilter(e.target.value || '')}
            />
          </div>
        </div>

        <Button
          variant="primary"
          className="!rounded-full mt-0"
          onClick={() => setSelectedAdminUser({
            firstName: '',
            lastName: '',
            email: '',
            permissions: [],
          } as AdminUser)}
        >
          <PlusIcon className="[&_path]:stroke-white" />
          Add Admin User
        </Button>

      </div>
      <div
        className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
      >
        <table className="w-full">
          <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
            <tr className="sticky top-0 z-[1]">
              {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                <div className="flex items-center cursor-pointer" onClick={sortToggleClick(header.value)}>
                  {header.label}
                  {sortField === header.value && <div>
                    {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                  </div>}
                </div>
              </td>))}
            </tr>
          </thead>
          <tbody>
            {sortedAdminUsers?.map((adminUser) => (
              <tr
                key={adminUser.id}
                className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                onClick={() => setSelectedAdminUser(adminUser)}
              >
                <td className="name pl-5">
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2.5">
                        <div className="inline-block overflow-none">
                          {adminUser?.firstName} {adminUser?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {adminUser.email}
                </td>
                <td>
                  {adminUser.permissions.length === FULL_PERMISSIONS.length ? 'FULL ACCESS' : `${adminUser.permissions.length} Permissions`}
                </td>
                <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                  {moment.utc(adminUser.createdAt).local().format("MMM DD,YYYY")}
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>
    {selectedAdminUser && <AdminUserModal adminUser={selectedAdminUser} onClose={
      () => {
        setSelectedAdminUser(undefined);
        refreshAdminUsers();
      }}
    />}
  </LoadAnimatedContainer >

}

