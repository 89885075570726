import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import { Button } from "../../../components/Button";

export function ResetPasswordSuccess({ userType }: { userType: string }) {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col items-center mx-3">
      <SuccessIcon />
      <div className="text-black text-[28px] font-semibold text-center mt-1 mb-4">
        Your password has been changed successfully!
      </div>
      <div className="text-black text-[16px] text-center">
        Please now login with your new password
      </div>
      <Button
        className="!rounded-full w-full mt-8"
        variant="primary"
        onClick={handleLoginClick}
      >
        Go to login
      </Button>
    </div>
  );
}
