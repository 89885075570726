import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import UIPopover from "../../../components/Popover";
import { useEffect, useState } from "react";
import moment from "moment";
import { EmptyStates } from "../../../components/EmptyStates";
import useProviders from "../hooks/useProviders";
import Modal from "../../../components/Modal";
import toast from "react-hot-toast";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { ProviderDeleteConfirm } from "../../dashboard/components/ProviderDeleteConfirm";
import { ImageView } from "../../../components/Image";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-2.5">
                  <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
                  <div className="w-28 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="subscription">
            <div className="w-24 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function BlockedProviderList({ isOpen, onClose, onRefresh }) {
  const { myPermissions } = useAdminUsers();
  const {
    updateFilters,
    providers,
    isLoading,
    refetch,
  } = useProviders();
  const [removeConfirmModal, setRemoveConfirmModal] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)


  const filteredProviders = providers?.filter((provider) => {
    if (filters.keyword) {
      return (
        provider.mobile?.toLowerCase().includes(filters.keyword.toLowerCase().trim()) ||
        provider.email?.toLowerCase().includes(filters.keyword.toLowerCase().trim()) ||
        provider.firstName?.toLowerCase().includes(filters.keyword.toLowerCase().trim()) ||
        provider.lastName?.toLowerCase().includes(filters.keyword.toLowerCase().trim())
      );
    }
    return true;
  });

  filteredProviders.sort((a, b) => {
    if (sortField === 'name') {
      const nameA = `${a.firstName} ${a.lastName}`;
      const nameB = `${b.firstName} ${b.lastName}`;
      return nameA.localeCompare(nameB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'mobile' && a.mobile && b.mobile) {
      return (sortASC ? (a.mobile < b.mobile) : (b.mobile < a.mobile)) ? -1 : 1;
    }
    if (sortField === 'email' && a.email && b.email) {
      return (sortASC ? (a.email < b.email) : (b.email < a.email)) ? -1 : 1;
    }
    if (sortField === 'status') {
      if (a.isAvailable && !b.isAvailable) return sortASC ? -1 : 1;
      if (!a.isAvailable && b.isAvailable) return sortASC ? 1 : -1;
      return 0;
    }

    if ((sortField === 'joinedOn') && a.joinedOn && b.joinedOn) {
      return (sortASC ? (a.joinedOn < b.joinedOn) : (b.joinedOn < a.joinedOn)) ? -1 : 1;
    }

    if ((sortField === 'lastActive')) {
      const lastActiveA = a.lastActive;
      const lastActiveB = b.lastActive;
      if (lastActiveA && !lastActiveB) return -1;
      if (lastActiveB && !lastActiveA) return 1;
      if (!lastActiveA && !lastActiveB) return 0;

      return (sortASC ? (lastActiveA < lastActiveB) : (lastActiveB < lastActiveA)) ? -1 : 1;
    }

    if ((!sortField || sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }

    return 0;
  })

  const headers = [
    { value: "name", label: "Name" },
    { value: "mobile", label: "Phone Number" },
    { value: "email", label: "Email" },
    { value: "joinedOn", label: "Joined On" },
  ]

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortASC(true)
        setSortField(undefined)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }


  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleUnBlock = async (user) => {
    try {
      toast.loading("Activating...");
      const response = await api.unBlockUser(user?.id, { isActive: true });
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        ShowToast({
          type: "success",
          message: "User Activated!",
        });
        refetch();
        onRefresh();
      } else {
        ShowToast({
          type: "error",
          message:
            response?.data?.message || "Something went wrong, Please try again",
        });
      }
    } catch (error: any) {
      toast.dismiss();
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    updateFilters({ showPending: false, showActive: false });
  }, []);

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <LoadAnimatedContainer className="max-w-[1116px] mx-auto">
        <div className="text-black text-3xl font-bold mb-5">
          Deactivated Providers
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => setFilters({ keyword: e.target.value })}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center"></div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "18%" }} />
                <col style={{ width: "29%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer text-nowrap" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {filteredProviders?.map((user) => (
                  <tr
                    key={user.id}
                    className="hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-2.5">
                        <div className="w-10 h-10">
                          {user.profileImg ? (
                            <ImageView
                              alt="user"
                              src={user.profileImg}
                              className="w-full h-full rounded-full object-cover"
                              loading="lazy"
                            />
                          ) : (
                            <div
                              className={`uppercase w-10 h-10 rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                            >
                              {user?.firstName?.[0] || ""}
                            </div>
                          )}
                        </div>
                        <h5 className="flex-1">
                          {user?.firstName} {user?.lastName}
                        </h5>
                      </div>
                    </td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        {user.mobile}
                      </div>
                    </td>
                    <td>{user?.email}</td>

                    <td className="mrr">
                      {moment.utc(user.joinedOn).local().format("MMM DD,YYYY")}
                    </td>

                    <td onClick={stopPropagate} className="pr-5">
                      {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_PROVIDERS) && (
                        <UIPopover
                          trigger={
                            <span className="block p-2 border border-gray-270 rounded-lg">
                              <More />
                            </span>
                          }
                          positions={["bottom", "top", "left", "right"]}
                        >
                          {(close) => (
                            <ProviderOptionsDropdown
                              onUnBlock={() => {
                                close();
                                handleUnBlock(user);
                              }}
                              onRemove={() => {
                                close();
                                setRemoveConfirmModal(user);
                              }}
                            />
                          )}
                        </UIPopover>
                      )}
                    </td>
                  </tr>
                ))}
                {isLoading && <LoadingSkeleton />}
                <div className="h-5"></div>
              </tbody>
            </table>
            {isLoading === false && !providers?.length && (
              <EmptyStates text="No providers found!" />
            )}
          </div>
        </div>
      </LoadAnimatedContainer>

      {removeConfirmModal && (
        <ProviderDeleteConfirm
          onCancel={() => setRemoveConfirmModal(null)}
          onSuccess={() => {
            onRefresh(removeConfirmModal.id, true);
            setRemoveConfirmModal(null);
            refetch(removeConfirmModal.id, true);
          }}
          user={removeConfirmModal}
        />
      )}
    </Modal>
  );
}

export const ProviderOptionsDropdown = ({ onUnBlock, onRemove }) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onUnBlock();
            }}
          >
            Activate
          </button>
        </li>
      </ul>
    </div>
  );
};
