import { useEffect, useState } from "react";
import { ChatBox } from "../mailbox/components/ChatBox";
import { ContactList } from "../mailbox/components/ContactList";
import { MobileChatBox } from "../mailbox/components/MobileChatBox";
import { AnimatePresence } from "framer-motion";
import { ContactCard } from "../mailbox/components/ContactCard";
import { useMailBox } from "../mailbox/context/mailbox-context";
import useResponsive from "../../services/useResponsive";
import { Loading } from "../../components/Loading/Loading";
import { ContactRemoveConfirm } from "./components/ContactRemoveConfirm";
import { SearchBar } from "./components/SearchBar";
import { UnreadConvCounts, useUser } from "../auth/context/user-context";
import { AdminChatContactTabs } from "./components/AdminChatContactTabs";
import { useAdminUsers } from "../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../manageAdmin/AdminUserList.types";

const filterContacts = (contacts: any[], search: string) => {
  return contacts.filter((contact: any) => {
    const fullName = (contact.guardianFirstName)
      ? `${contact.firstName} ${contact.lastName} ${contact.guardianFirstName} ${contact.guardianLastName}`
      : `${contact.firstName} ${contact.lastName}`;
    return fullName.toLowerCase().includes(search.toLowerCase());
  });
};

export function AdminChat() {
  const { isSmallDevice } = useResponsive();
  const [mobileChatBox, setMobileChatBox] = useState(false);
  const [confirmRemoveContact, setConfirmRemoveContact] = useState(false);
  const [search, setSearch] = useState("");
  const { myPermissions } = useAdminUsers();
  const { selectedProviderToMessage, setSelectedProviderToMessage } =
    useUser() as any;
  const {
    selectedContact,
    setSelectedContact,
    markConversationUnread,
    markUnreadLoading,
    contactList,
    sendMessage,
    chatMessages,
    contactListLoading,
    messagesLoading,
    removeSchedule,
    removingSchedule,
    selectedContactStatus,
    setActiveTab,
    activeTab,
    insuranceDetails,
    secondaryInsuranceDetails,
    doesUserHaveWritePermissionForCurrentChat,
  } = useMailBox() as any;
  const { unreadConvCounts } = useUser() as { unreadConvCounts: UnreadConvCounts };
  const handleRemoveContact = () => {
    setConfirmRemoveContact(true);
  };
  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const isReadOnlyChat = !doesUserHaveWritePermissionForCurrentChat();

  const prioritizedContactList = [...contactList.filter(contact => contact?.unReadMessagesCount > 0), ...contactList.filter(contact => contact?.unReadMessagesCount === 0)];

  useEffect(() => {
    if (isSmallDevice) {
      if (selectedContact?.id) {
        setMobileChatBox(true);
      } else {
        setMobileChatBox(false);
      }
    } else {
      setMobileChatBox(false);
    }
  }, [isSmallDevice, selectedContact]);

  useEffect(() => {
    if (selectedProviderToMessage && prioritizedContactList.length > 0) {
      const contact = prioritizedContactList.find(
        (contact: any) => contact.id === selectedProviderToMessage.id
      );
      if (contact) {
        setSelectedProviderToMessage();
        setSelectedContact(contact);
      }
    }
  }, [prioritizedContactList, selectedProviderToMessage]);

  return (
    <div>
      <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
        <div className="text-black text-3xl font-bold">Message</div>
        <div className="flex justify-center"></div>
      </div>
      <div className="flex gap-2.5 items-center lg:max-w-[1240px] mx-auto">
        <AdminChatContactTabs
          onTabChange={(tab: string) => {
            setActiveTab(tab);
            setSelectedContact(null);
          }}
          activeTab={activeTab}
          unreadConvCounts={unreadConvCounts}
        />
      </div>
      <div className="flex gap-2.5 items-center lg:max-w-[1240px] mx-auto">
        <ContactList containerClassName="!flex-1 lg:flex-[0] !h-[calc(100vh-140px)] lg:!h-[calc(100vh-125px)]">

          <SearchBar onChange={handleSearchChange} />
          <div className="flex flex-col gap-3 max-h-[calc(100vh-220px)] overflow-auto">
            {contactListLoading && (
              <div className="flex justify-center items-center [&>svg]:w-8 [&>svg]:h-8 mt-16">
                <Loading />
              </div>
            )}
            {filterContacts([...prioritizedContactList], search).map((contact: any) => (
              <ContactCard
                unreadConvCounts={unreadConvCounts}
                key={contact.id}
                active={selectedContact?.id === contact.id}
                data={{
                  ...contact,
                  clientName: contact?.guardianFirstName ? `${contact?.firstName} ${contact?.lastName}` : "Deleted User",
                }}
                unreadCount={contact?.unReadMessagesCount}
                cardType={activeTab === "clients" ? "providers" : "family"}
                onMarkUnread={async () => {
                  await markConversationUnread(contact.id);
                  if (selectedContact?.id === contact.id) {
                    setSelectedContact(null);
                  }
                }}
                markUnreadLoading={markUnreadLoading}
                onClick={() => {
                  if (selectedContact?.id === contact.id) return;
                  setSelectedContact(contact);
                  if (isSmallDevice) {
                    setMobileChatBox(true);
                  }
                }}
              />
            ))}
          </div>
        </ContactList>
        <div className="hidden lg:block flex-1">
          <ChatBox
            isReadOnlyChat={isReadOnlyChat}
            selectedContact={selectedContact}
            selectedContactStatus={selectedContactStatus}
            messages={chatMessages}
            messagesLoading={messagesLoading}
            containerClassName="h-[calc(100vh-170px)] lg:h-[calc(100vh-125px)]"
            onSend={(data: any) => {
              sendMessage(data);
            }}
            type={activeTab === "clients" ? "PF" : "P"}
            onRemove={handleRemoveContact}
            insuranceDetails={insuranceDetails}
            secondaryInsuranceDetails={secondaryInsuranceDetails}
            markUnreadLoading={markUnreadLoading}
            onMarkUnread={() => {
              markConversationUnread(selectedContact.id)
              setSelectedContact(null);
            }}
          />
        </div>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {mobileChatBox && (
          <MobileChatBox
            isReadOnlyChat={isReadOnlyChat}
            onClose={() => setMobileChatBox(false)}
            messages={chatMessages}
            onSend={(data: any) => {
              sendMessage(data);
            }}
            type={activeTab === "clients" ? "PF" : "P"}
            selectedContact={selectedContact}
            messagesLoading={messagesLoading}
            onRemove={handleRemoveContact}
            selectedContactStatus={selectedContactStatus}
            insuranceDetails={insuranceDetails}
            secondaryInsuranceDetails={secondaryInsuranceDetails}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {confirmRemoveContact && (
          <ContactRemoveConfirm
            onCancel={() => setConfirmRemoveContact(false)}
            onConfirm={() => {
              removeSchedule(selectedContact.scheduleId).then(() => {
                setConfirmRemoveContact(false);
              });
            }}
            deleting={removingSchedule}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
