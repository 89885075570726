export const GOOGLE_OAUTH_CLIENT_ID = "799934358315-8a677t2mrpg56po2okc9jkqa4sgucju2.apps.googleusercontent.com";

export const USER_TYPES = {
  FAMILY: "family",
  PROVIDER: "provider",
  ADMIN: "admin",
};

export const DOCUMENT_OWNER_TYPES = {
  USER: "U",
  ADMIN: "S",
};

export const focusAreas = [
  {
    label: "Naturalistic Developmental Behavioral Interventions (NDBI)",
    value: "Naturalistic Developmental Behavioral Interventions",
  },
  {
    label: "Early Intensive Behavioral Intervention (EIBI)",
    value: "Early Intensive Behavioral Intervention",
  },
  {
    label: "Early Start Denver Model (ESDM)",
    value: "Early Start Denver Model",
  },
  {
    label: "Pivotal Response Treatment (PRT)",
    value: "Pivotal Response Treatment",
  },
  {
    label: "Incidental Teaching (IT)",
    value: "Incidental Teaching",
  },
  {
    label: "Verbal Behavior Intervention (VBI)",
    value: "Verbal Behavior Intervention",
  },
  {
    label: "Social Skills Group",
    value: "Social Skills Group",
  },
  {
    label: "Challenging Behavior",
    value: "Challenging Behavior",
  },
];

export const acceptedInsurances = [
  {
    label: "Aetna (Commercial)",
    value: "Aetna",
    states: ["Indiana", "Georgia", "Texas", "Arizona", "North Carolina"],
  },
  {
    label: "Anthem / Blue Cross Blue Shield (Commercial)",
    value: "Anthem Blue Cross Blue Shield",
    states: ["Indiana", "Georgia"],
  },
  {
    label: "Cigna / Evernorth (Commercial)",
    value: "Evernorth Cigna",
    states: ["Indiana", "Georgia"],
  },
  {
    label: "United / Optum (Commercial)",
    value: "Optum United Healthcare",
    states: ["Indiana", "Georgia"],
  },
  {
    label: "PHP / Encore (Commercial)",
    value: "Physicians Health Plan",
    states: ["Indiana"],
  },
  {
    label: "Indiana State Medicaid",
    value: "Medicaid",
    states: ["Indiana"],
  },
  {
    label: "MDWise (Medicaid)",
    value: "MDwise Medicaid",
    states: ["Indiana"],
  },
  {
    label: "MHS (Medicaid)",
    value: "Managed Health Services Medicaid",
    states: ["Indiana"],
  },
  {
    label: "Caresource (Medicaid)",
    value: "Caresource IN Medicaid",
    states: ["Indiana"],
  },
  {
    label: "Anthem / Blue Cross Blue Shield (Medicaid)",
    value: "Anthem Medicaid",
    states: ["Indiana"],
  },
  {
    label: "Children's Special Health Care Services / CSHCS",
    value: "CSHCS",
    states: ["Indiana"],
  },

  // Georgia specific
  {
    label: "Georgia State Medicaid",
    value: "Georgia State Medicaid",
    states: ["Georgia"],
  },
  {
    label: "Peach State Health Plan (Medicaid)",
    value: "Peach State GA Medicaid",
    states: ["Georgia"],
  },
  {
    label: "Caresource (Medicaid)",
    value: "Caresource GA Medicaid",
    states: ["Georgia"],
  },
  {
    label: "Amerigroup (Medicaid)",
    value: "Amerigroup GA Medicaid",
    states: ["Georgia"],
  },

  // Texas specific
  {
    label: "Aetna Better Health",
    value: "Aetna Better Health",
    states: ["Texas"],
  },
  {
    label: "Amerigroup",
    value: "Amerigroup",
    states: ["Texas"],
  },
  {
    label: "BCBS TX (Commercial/Medicaid)",
    value: "BCBS TX Commercial Medicaid",
    states: ["Texas"],
  },
  {
    label: "Community Health Choice",
    value: "Community Health Choice",
    states: ["Texas"],
  },
  {
    label: "Cooks Children",
    value: "Cooks Children",
    states: ["Texas"],
  },
  {
    label: "Evernorth",
    value: "Evernorth",
    states: ["Texas", "Arizona", "North Carolina"],
  },
  {
    label: "Molina",
    value: "Molina",
    states: ["Texas", "Arizona"],
  },
  {
    label: "Optum",
    value: "Optum",
    states: ["Texas", "Arizona", "North Carolina"],
  },
  {
    label: "Parkland",
    value: "Parkland",
    states: ["Texas"],
  },
  {
    label: "Superior",
    value: "Superior",
    states: ["Texas"],
  },
  {
    label: "Tricare",
    value: "Tricare",
    states: ["Texas"],
  },
  {
    label: "Texas Childrens",
    value: "Texas Childrens",
    states: ["Texas"],
  },
  {
    label: "TMHP-TX Medicaid",
    value: "TMHP-TX Medicaid",
    states: ["Texas"],
  },

  // Arizona specific
  {
    label: "AZ Medicaid",
    value: "AZ Medicaid",
    states: ["Arizona"],
  },
  {
    label: "Banner Health",
    value: "Banner Health",
    states: ["Arizona"],
  },
  {
    label: "BCBS AZ",
    value: "BCBS AZ",
    states: ["Arizona"],
  },
  {
    label: "Carefirst (Medicaid)",
    value: "Carefirst Medicaid",
    states: ["Arizona"],
  },
  {
    label: "Complete Health",
    value: "Complete Health",
    states: ["Arizona"],
  },
  {
    label: "Health Choice",
    value: "Health Choice",
    states: ["Arizona"],
  },
  {
    label: "Mercy Care",
    value: "Mercy Care",
    states: ["Arizona"],
  },

  // North Carolina specific
  {
    label: "Alliance",
    value: "Alliance",
    states: ["North Carolina"],
  },
  {
    label: "Amerihealth",
    value: "Amerihealth",
    states: ["North Carolina"],
  },
  {
    label: "BCBS NC",
    value: "BCBS NC",
    states: ["North Carolina"],
  },
  {
    label: "Healthy Blue NC",
    value: "Healthy Blue NC",
    states: ["North Carolina"],
  },
  {
    label: "NC Medicaid",
    value: "NC Medicaid",
    states: ["North Carolina"],
  },
  {
    label: "Partners",
    value: "Partners",
    states: ["North Carolina"],
  },
  {
    label: "Vaya",
    value: "Vaya",
    states: ["North Carolina"],
  },
  {
    label: "Wellcare",
    value: "Wellcare",
    states: ["North Carolina"],
  },
  {
    label: "Carolina Complete",
    value: "Carolina Complete",
    states: ["North Carolina"],
  },
].sort((a, b) => a.label.localeCompare(b.label));
