import React, { useEffect, useMemo, useState } from "react";
import { AuthRequest, AuthRequestClient } from "../RequestAuth.types";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

export type AuthRequestProviderProps = {
  authRequests: Array<AuthRequest>;
  allPatients: Array<AuthRequestClient>;
  refreshRequests: () => {}
}

export const RequestAuthContext = React.createContext<AuthRequestProviderProps>({
  authRequests: [],
  allPatients: [],
  refreshRequests: () => ({}),
});

export function AuthRequestProvider({ children }) {
  const { myPermissions } = useAdminUsers();
  const user = getUser();
  const [requests, setRequests] = useState<Array<AuthRequest>>([]);
  const [allPatients, setAllPatients] = useState<Array<AuthRequestClient>>([]);

  const fetchRequests = useMemo(() => async () => {
    if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_AUTH_REQUEST)) {
      setRequests(await api.getAuthRequestsAsAdmin())
    }
  }, [myPermissions]);

  const fetchAllPatients = useMemo(() => async () => {
    if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_AUTH_REQUEST)) {
      setAllPatients(await api.getAllPatients())
    }
  }, [myPermissions]);

  useEffect(() => {
    if (user?.id) {
      fetchRequests();
      fetchAllPatients();
    }
  }, [user?.id, myPermissions]);

  const values = useMemo(() => ({
    authRequests: requests,
    refreshRequests: fetchRequests,
    allPatients: allPatients
  }), [requests, fetchRequests, allPatients]);

  return <RequestAuthContext.Provider value={values}>{children}</RequestAuthContext.Provider>;
}

export function useAuthRequests() {
  const context = React.useContext<AuthRequestProviderProps>(RequestAuthContext);
  if (context === undefined) {
    throw new Error("useAuthRequests must be used within a AuthRequestProvider");
  }
  return context;
}
