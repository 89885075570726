import { useEffect, useRef } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { isMe } from "../../../services/utils";
import { Editor } from "./Editor";
import { MessageView } from "./Message";
import { Dropdown } from "../../../components/Dropdown";
import React from "react";
import { FamilyProfileView } from "./FamilyProfileView";
import { ProviderDetails } from "../../providers/ProviderDetails";
import { AnimatePresence } from "framer-motion";
import { EmptyStates } from "../../../components/EmptyStates";
import { Loading } from "../../../components/Loading/Loading";
import { InquiryActionBar } from "./InquiryActionBar";
import { SmsPromptConfirm } from "./SmsPromptConfirm";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";

const getIsPreviousSenderSame = (message: any, prevMessage?: any) => {
  // only date should be compared
  return (
    prevMessage &&
    prevMessage.isPatientSender === message.isPatientSender &&
    new Date(prevMessage.createdAt).toDateString() ===
    new Date(message.createdAt).toDateString()
  );
};

export function ChatBox({
  containerClassName,
  isReadOnlyChat = false,
  isMobile,
  onClose,
  messages,
  onSend,
  type,
  selectedContact,
  messagesLoading,
  onRemove,
  isInquiry,
  onStartChat,
  onDecline,
  selectedContactStatus,
  insuranceDetails,
  secondaryInsuranceDetails,
  onMarkUnread,
  markUnreadLoading,
}: {
  containerClassName?: string;
  isReadOnlyChat: boolean;
  onClose?: () => void;
  isMobile?: boolean;
  messages: any[];
  onSend: (data: any) => void;
  type: string;
  selectedContact?: any;
  messagesLoading: boolean;
  onRemove: () => void;
  isInquiry?: boolean;
  onStartChat?: () => void;
  onDecline?: () => void;
  selectedContactStatus: any;
  insuranceDetails?: any;
  secondaryInsuranceDetails?: any;
  onMarkUnread?: () => void;
  markUnreadLoading?: boolean;
}) {
  const [isProfileOpen, setIsProfileOpen] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [showPromptViaSmsModal, setShowPromptViaSmsModal] = React.useState(false);
  const oldMessages = useRef<any>([]);
  const messagesEndRef = useRef<any>(null);
  const handleSend = (data: any) => {
    onSend(data);
  };
  useEffect(() => {
    if (messagesEndRef.current && messagesEndRef.current.scrollTop && messagesEndRef.current && messagesEndRef.current.scrollHeight) {
      messagesEndRef.current.scrollTop = messagesEndRef.current?.scrollHeight;
    }
  }, [selectedContact, isInquiry]);

  useEffect(() => {
    // scroll to bottom
    // only scroll if new message is added
    if (
      !messagesLoading &&
      JSON.stringify(oldMessages.current) !== JSON.stringify(messages)
    ) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current?.scrollHeight;
      }
      oldMessages.current = messages;
    }
  }, [messages, messagesLoading]);

  const handleRemoveContact = () => {
    onRemove();
  };
  const isSenderFamily = type === "PF";
  if (!selectedContact) {
    return (
      <div
        className={`bg-white rounded-[16px] flex-1 h-full ${containerClassName}`}
      >
        <div className="h-full flex items-center">
          <EmptyStates text={""} />
        </div>
      </div>
    );
  }
  return (
    <div
      className={`bg-white rounded-[16px] flex-1 h-full flex flex-col ${containerClassName}`}
    >
      <div className="px-4 md:px-6 pt-3 pb-2 md:py-5 bg-white border-b border-gray-300 justify-between items-center flex w-full rounded-t-[16px]">
        <div className="justify-start md:items-center md:gap-3.5 flex flex-col md:flex-row">
          <div
            role="button"
            onClick={() => setIsProfileOpen(true)}
            className="text-black text-xl font-semibold"
          >
            {
              type === "PF" ?
                (selectedContact?.guardianFirstName ? `${selectedContact?.firstName} ${selectedContact?.lastName}` : `[Deleted User]`) :
                (selectedContact?.firstName && `${selectedContact?.firstName} ${selectedContact?.lastName}`)
            }
          </div>

          <div className="justify-start items-center gap-1.5 flex">
            {selectedContactStatus?.status === "Active now" && (
              <div className="w-3 h-3 bg-[#2EB67D] rounded-full" />
            )}
            <div className="text-black/70 text-sm font-normal">
              {selectedContactStatus?.status}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          {!isReadOnlyChat &&
            <button
              onClick={() => setShowPromptViaSmsModal(true)}
              className="pt-1.5 px-2 bg-primary text-white h-8 my-auto text-sm pb-3 rounded-lg justify-start items-start gap-2.5 flex"
            >
              SMS Prompt
            </button>
          }
          <div className="relative">
            <button onClick={() => setOpenDropdown(true)}>
              <div className="flex gap-[6.30px] items-center p-3 mr-[-10px]">
                <span className="w-[5.49px] h-[5.49px] rounded-full bg-[#00AE9B]" />
                <span className="w-[5.49px] h-[5.49px] rounded-full bg-[#00AE9B]" />
              </div>
            </button>
            <Dropdown
              padding="10px"
              className="!mt-[2px] w-[180px]"
              isOpen={openDropdown}
              setIsOpen={() => setOpenDropdown(false)}
            >
              {!markUnreadLoading && !isReadOnlyChat &&
                <button
                  className="w-full px-4 !py-3 text-[#212424] text-base hover:bg-[rgba(210,226,213,0.30)] rounded-lg"
                  onClick={() => {
                    setOpenDropdown(false);
                    onMarkUnread?.();

                  }}
                >
                  Mark Unread
                </button>
              }
              <button
                className="w-full px-4 !py-3 text-[#212424] text-base hover:bg-[rgba(210,226,213,0.30)] rounded-lg"
                onClick={() => {
                  setOpenDropdown(false);
                  setIsProfileOpen(true);
                }}
              >
                View Profile
              </button>
              {!isReadOnlyChat && (
                <button
                  className="text-[#E3270E] text-base !py-3 px-4 hover:bg-[rgba(210,226,213,0.30)] rounded-lg"
                  onClick={() => {
                    setOpenDropdown(false);
                    handleRemoveContact();
                  }}
                >
                  Remove
                </button>
              )}
            </Dropdown>
          </div>
          {isMobile && (
            <button onClick={onClose} className="pt-2">
              <CloseIcon className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
      <div
        ref={(e) => (messagesEndRef.current = e)}
        className={`flex-1 flex flex-col gap-4 p-5 ${isInquiry
          ? "h-[calc(100%-160px)] lg:h-[calc(100%-155px)]"
          : "h-[calc(100%-160px)] lg:h-[calc(100%-170px)]"
          } overflow-auto`}
      >
        {messagesLoading && (
          <div className="flex justify-center items-center h-full [&>svg]:w-8 [&>svg]:h-8 ">
            <Loading />
          </div>
        )}
        {messages.length === 0 && !messagesLoading && (
          <div className="flex justify-center items-center h-full">
            <EmptyStates text="No Messages" />
          </div>
        )}
        {messages.map((message, i) => (
          <MessageView
            key={message.id}
            isMe={isMe(type, message.isPatientSender)}
            message={message}
            prevMessageDate={messages[i - 1]?.createdAt}
            senderProfileImg={selectedContact?.profileImg}
            senderName={selectedContact?.guardianFirstName ?? selectedContact?.firstName}
            isPreviousSenderSame={getIsPreviousSenderSame(
              message,
              messages[i - 1]
            )}
            onProfileImageClick={() => setIsProfileOpen(true)}
          />
        ))}
      </div>
      <div className="p-3">
        {isInquiry ? (
          <InquiryActionBar onDecline={onDecline} onStartChat={onStartChat} />
        ) : !isReadOnlyChat &&
        (
          <Editor onSend={handleSend} />
        )}
      </div>
      {showPromptViaSmsModal && (<SmsPromptConfirm
        onConfirm={async () => {
          setShowPromptViaSmsModal(false);
          const result = await api.promptUserViaSmsAsAdmin(selectedContact?.id);
          console.log(result.data);
          if (result.status === 200 || result.status === 201) {
            console.log(result.data.success);
            if (result.data.success) {
              ShowToast({
                message: `You've successfully sent a SMS to ${selectedContact?.guardianFirstName ?? selectedContact?.firstName} ${(selectedContact?.mobile && `(${selectedContact?.mobile})`) ?? ''}`,
                type: "success",
              });
            } else {
              ShowToast({
                message: `Failed to send SMS. Could be a wrong mobile number ${selectedContact?.mobile ?? ''}`,
                type: "error",
              });
            }
          }
        }} onCancel={function (): void {
          setShowPromptViaSmsModal(false);
        }} />)}
      <AnimatePresence mode="wait" initial={false}>
        {isSenderFamily ? (
          isProfileOpen ? (
            <FamilyProfileView
              user={selectedContact}
              insurance={{ ...insuranceDetails }}
              secondaryInsurance={{ ...secondaryInsuranceDetails }}
              onClose={() => setIsProfileOpen(false)}
              onMessage={() => setIsProfileOpen(false)}
            />
          ) : null
        ) : (
          <>
            {isProfileOpen ? (
              <ProviderDetails
                provider={selectedContact}
                onClose={() => setIsProfileOpen(false)}
                isOpen={isProfileOpen}
                onSchedule={() => { }}
              />
            ) : null}
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
