export type AdminUser = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt?: string;
  permissions: AdminPermission[];
}

export type CreateAdminUserDto = {
  firstName: string;
  lastName: string;
  email: string;
  permissions?: AdminPermission[];
}

export type UpdateAdminUserDto = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: AdminPermission[];
}

export enum AdminPermission {

  // CHATS
  READ_CHAT_GENERAL_ADMIN = "readGeneralAdminChat",
  WRITE_CHAT_GENERAL_ADMIN = "writeGeneralAdminChat",
  READ_CHAT_RBT_ADMIN = "readRbtAdminChat",
  WRITE_CHAT_RBT_ADMIN = "writeRbtAdminChat",
  READ_CHAT_CLIENT_ACQ_ADMIN = "readClientAcqAdminChat",
  WRITE_CHAT_CLIENT_ACQ_ADMIN = "writeClientAcqAdminChat",
  READ_CHAT_INS_OPS_ADMIN = "readInsOpsAdminChat",
  WRITE_CHAT_INS_OPS_ADMIN = "writeInsOpsAdminChat",
  READ_CHAT_CLIENT = "readClientChat",
  WRITE_CHAT_CLIENT = "writeClientChat",

  // PAGES
  READ_PAGE_MANAGE_CLIENTS = "readPageManageClients",
  WRITE_PAGE_MANAGE_CLIENTS = "writePageMangeClient",
  READ_PAGE_MANAGE_PROVIDERS = "readPageManageProviders",
  WRITE_PAGE_MANAGE_PROVIDERS = "writePageMangeProviders",
  READ_PAGE_MANAGE_REFERRALS = "readPageManageReferrals",
  WRITE_PAGE_MANAGE_REFERRALS = "writePageMangeReferrals",
  READ_PAGE_MANAGE_AUTH_REQUEST = "readPageManageAuthRequest",
  WRITE_PAGE_MANAGE_AUTH_REQUEST = "writePageMangeAuthRequest",
  READ_PAGE_MANAGE_RBT = "readPageManage",
  WRITE_PAGE_MANAGE_RBT = "writePageManage",
  READ_PAGE_MANAGE_BULLETIN = "readPageManageBulletin",
  WRITE_PAGE_MANAGE_BULLETIN = "writePageManageBulletin",
  READ_PAGE_MANAGE_LIBRARY = "readPageManageLibrary",
  WRITE_PAGE_MANAGE_LIBRARY = "writePageManageLibrary",

  // SUPER POWERS
  MANAGE_ADMIN = "manageAdmin",
  PROVIDER_ACCOUNT_ACCESS = "providerAccountAccess",
  CLIENT_ACCOUNT_ACCESS = "clientAccountAccess",
}

export const groupedPermissions = {
  "Chat: General Admin": {
    read: AdminPermission.READ_CHAT_GENERAL_ADMIN,
    write: AdminPermission.WRITE_CHAT_GENERAL_ADMIN,
  },
  "Chat: RBT Admin": {
    read: AdminPermission.READ_CHAT_RBT_ADMIN,
    write: AdminPermission.WRITE_CHAT_RBT_ADMIN,
  },
  "Chat: Client Acquisition Admin": {
    read: AdminPermission.READ_CHAT_CLIENT_ACQ_ADMIN,
    write: AdminPermission.WRITE_CHAT_CLIENT_ACQ_ADMIN,
  },
  "Chat: Insurance Ops Admin": {
    read: AdminPermission.READ_CHAT_INS_OPS_ADMIN,
    write: AdminPermission.WRITE_CHAT_INS_OPS_ADMIN,
  },
  "Chat: Client": {
    read: AdminPermission.READ_CHAT_CLIENT,
    write: AdminPermission.WRITE_CHAT_CLIENT,
  },
  "Page: Clients": {
    read: AdminPermission.READ_PAGE_MANAGE_CLIENTS,
    write: AdminPermission.WRITE_PAGE_MANAGE_CLIENTS,
  },
  "Page: Providers": {
    read: AdminPermission.READ_PAGE_MANAGE_PROVIDERS,
    write: AdminPermission.WRITE_PAGE_MANAGE_PROVIDERS,
  },
  "Page: Referrals": {
    read: AdminPermission.READ_PAGE_MANAGE_REFERRALS,
    write: AdminPermission.WRITE_PAGE_MANAGE_REFERRALS,
  },
  "Page: Auth Request": {
    read: AdminPermission.READ_PAGE_MANAGE_AUTH_REQUEST,
    write: AdminPermission.WRITE_PAGE_MANAGE_AUTH_REQUEST,
  },
  "Page: RBT Requests & Manage RBTs": {
    read: AdminPermission.READ_PAGE_MANAGE_RBT,
    write: AdminPermission.WRITE_PAGE_MANAGE_RBT,
  },
  "Page: Bulletin": {
    read: AdminPermission.READ_PAGE_MANAGE_BULLETIN,
    write: AdminPermission.WRITE_PAGE_MANAGE_BULLETIN,
  },
  "Page: Library": {
    read: AdminPermission.READ_PAGE_MANAGE_LIBRARY,
    write: AdminPermission.WRITE_PAGE_MANAGE_LIBRARY,
  },
  "Provider Account Access": {
    write: AdminPermission.PROVIDER_ACCOUNT_ACCESS,
  },
  "Client Account Access": {
    write: AdminPermission.CLIENT_ACCOUNT_ACCESS,
  },
  "Manage Admin Users": {
    write: AdminPermission.MANAGE_ADMIN,
  },
}

export function getEnumKeyByEnumValue<
  TEnumKey extends string,
  TEnumVal extends string | number
>(myEnum: { [key in TEnumKey]: TEnumVal }, enumValue: TEnumVal): string {
  const keys = (Object.keys(myEnum) as TEnumKey[]).filter(
    (x) => myEnum[x] === enumValue,
  );
  return keys.length > 0 ? keys[0] : '';
}

export const getAdminPermissionEnumByValue = (value: string) => {
  const asEnum = AdminPermission[getEnumKeyByEnumValue(AdminPermission, value)] as AdminPermission
  return asEnum
}

export const FULL_PERMISSIONS: AdminPermission[] = Object.values(AdminPermission)