import axios, { AxiosError, AxiosResponse } from "axios";
import { BACKEND_URL } from "../env";
import { ShowToast } from "../services/toast";

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return `Bearer ${token}`;
  }
  return null;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === 401) {
    const errorMessage = (error.response?.data as { message?: string })?.message;
    if (errorMessage === "bad login") {
      localStorage.removeItem("token");
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/provider/login"
      ) {
        window.location.replace("/login");
      }
    } else {
      ShowToast({
        type: "error",
        message: (error?.response?.data as { message: string })?.message || "Something went wrong",
      });
    }
  }
  return Promise.reject(error);
};

const apiClient = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

apiClient.interceptors.request.use(
  (config: any) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(onResponse, onResponseError);

export default apiClient;
