import Select from "react-select";

export function MultiSelect({
  options,
  onChange,
  value,
  name,
  className,
  disabled = false
}: {
  options: { label: string; value: string }[];
  onChange: any;
  value: string[];
  name: string;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <div>
      <Select
        isDisabled={disabled}
        isMulti
        name={name}
        options={options}
        value={value.map(
          (v) => options.find((o) => o.value === v) || { label: v, value: v }
        )}
        formatOptionLabel={(data) => data.label}
        classNamePrefix="select"
        isClearable={false}
        onChange={(selectedOptions) => onChange(selectedOptions)}
        className={className}
      />
    </div>
  );
}
