import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as FolderIcon } from "../../assets/icons/folder.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/message.svg";
import { ReactComponent as BulletinIcon } from "../../assets/icons/bulletin.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/person.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as ReferralIcon } from "../../assets/icons/referral.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list.svg";
import { ReactComponent as ListTicksIcon } from "../../assets/icons/list-ticks.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { UserButton } from "../UserButton/UserButton";
import { getUser } from "../../services/utils";
import { Dropdown } from "../Dropdown";
import { LogoutConfirm } from "./LogoutConfirm";
import { useUser } from "../../pages/auth/context/user-context";
import { SettingsModal } from "./SettingsModal";
import { useAdminUsers } from "../../pages/manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../pages/manageAdmin/AdminUserList.types";
import { ChangePassword } from "../../pages/provider/profile/ChangePassword";

export function MenuItem({
  href,
  active,
  title,
  Icon,
  extra,
}: {
  href: string;
  active?: boolean;
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  extra?: React.ReactNode;
}) {
  return (
    <Link
      to={href}
      className={`p-4 hover:bg-secondary ${active ? "!bg-primary" : ""
        } w-full rounded-lg justify-start items-center gap-2.5 flex`}
    >
      <Icon
        className={`w-6 h-6 relative ${active ? "[&_path]:stroke-white" : ""}`}
      />
      <div className={`${active ? "text-white" : ""} text-lg font-normal`}>
        {title}
      </div>
      {extra}
    </Link>
  );
}

export function MenuItemList({ count }) {
  const pathname = window.location.pathname;
  const { myPermissions } = useAdminUsers()

  const menuItemList = [<MenuItem
    href="/dashboard"
    title="Dashboard"
    Icon={DashboardIcon}
    active={pathname === "/dashboard"}
    key="dashboard"
  />]

  if (myPermissions.some(p => [
    AdminPermission.READ_CHAT_CLIENT,
    AdminPermission.READ_CHAT_GENERAL_ADMIN,
    AdminPermission.READ_CHAT_INS_OPS_ADMIN,
    AdminPermission.READ_CHAT_RBT_ADMIN,
    AdminPermission.READ_CHAT_RBT_ADMIN
  ].includes(p))) {
    menuItemList.push(<MenuItem
      href="/messages"
      title="Messages"
      key="messages"
      Icon={MessageIcon}
      active={pathname === "/messages"}
      extra={
        count ? (
          <div
            className={`w-7 h-5 ${pathname === "/messages"
              ? "text-primary bg-white"
              : "text-white bg-primary"
              } rounded-3xl flex-col justify-start items-center gap-2.5 flex`}
          >
            <div className="text-sm font-normal">{count}</div>
          </div>
        ) : null
      }
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_CLIENTS)) {
    menuItemList.push(<MenuItem
      href="/clients"
      key="clients"
      title="Manage Clients"
      Icon={UsersIcon}
      active={pathname === "/clients"}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_REFERRALS)) {
    menuItemList.push(<MenuItem
      href="/client-referrals"
      title="Manage Referrals"
      key="referrals"
      Icon={ReferralIcon}
      active={pathname === "/client-referrals"}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_PROVIDERS)) {
    menuItemList.push(<MenuItem
      href="/providers"
      title="Manage Providers"
      key="providers"
      Icon={PersonIcon}
      active={pathname === "/providers"}
    />)
  }
  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_AUTH_REQUEST)) {
    menuItemList.push(<MenuItem
      href="/auth-requests"
      key="auth-requests"
      title="Auth Requests"
      Icon={ListTicksIcon}
      active={pathname === "/auth-requests"}
    />)
  }
  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_RBT)) {
    menuItemList.push(<MenuItem
      href="/rbt-requests"
      key="rbt-requests"
      title="RBT Requests"
      Icon={ListIcon}
      active={pathname === "/rbt-requests"}
    />)
    menuItemList.push(<MenuItem
      href="/rbt-candidates"
      key="rbt-candidates"
      title="Manage RBTs"
      Icon={UsersIcon}
      active={pathname === "/rbt-candidates"}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_BULLETIN)) {
    menuItemList.push(<MenuItem
      href="/bulletin"
      key="bulletin"
      title="Manage Bulletin"
      Icon={BulletinIcon}
      active={pathname === "/bulletin"}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_LIBRARY)) {
    menuItemList.push(<MenuItem
      href="/library"
      key="library"
      title="Manage Library"
      Icon={FolderIcon}
      active={pathname === "/library"}
    />)
  }

  return (
    <div className="w-full flex-col justify-start items-start gap-2.5 flex">
      {menuItemList}
    </div>
  );
}

export function Menu() {
  const user = getUser();
  const { myPermissions, initialPermissionsLoaded } = useAdminUsers()
  const { userInfo = {}, totalUnreadConvCount } = useUser() as any;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleLogoutConfirm = () => {
    setLogoutConfirm(false);
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleLogout = () => {
    setOpenDropdown(false);
    setLogoutConfirm(true);
  };

  const handleSettings = () => {
    setOpenDropdown(false);
    setSettingsModal(true);
  };

  const handleSettingsClose = () => {
    setSettingsModal(false);
  };

  if (!initialPermissionsLoaded) {
    return null
  }

  return (
    <>
      <div className="w-[264px] flex-col bg-white justify-start items-start flex">
        <div className="px-7 py-6 w-full border-b border-gray-200 flex-col justify-center items-start gap-2.5 flex">
          <Logo className="w-[143px] h-7" />
        </div>
        <div className="self-stretch grow shrink basis-0 p-3 bg-white border-b border-black border-opacity-10 backdrop-blur-[27px] flex-col justify-start items-start gap-96 flex">
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-center flex">
            <div className="w-full flex-col justify-center items-start gap-1 flex">
              <MenuItemList count={totalUnreadConvCount} />
            </div>
            <div className="block relative w-full">
              <UserButton
                className="w-full [&>div]:!mx-0 !mb-0"
                variant="sideMenu"
                user={{ ...user, ...userInfo }}
                onClick={() => setOpenDropdown(true)}
              />
              <Dropdown
                padding="15px"
                className="w-full !top-[-110px] !justify-start !items-start flex"
                isOpen={openDropdown}
                setIsOpen={() => setOpenDropdown(false)}
              >

              <button
                  className="h-8 text-left text-base w-full"
                  onClick={() => setShowChangePassword(true)}
                >
                  Change Password
              </button>

                {myPermissions.includes(AdminPermission.MANAGE_ADMIN) &&
                  <Link to={"/manage-admin"}>
                    <button
                      className="text-black hover:text-black/80 h-8 text-left text-base w-full"
                      onClick={() => setOpenDropdown(false)}
                    >
                      Manage Admin
                    </button>
                  </Link>
                }
                {/* <button
                  className="text-black hover:text-black/80 h-8 text-left text-base w-full mb-2"
                  onClick={handleSettings}
                >
                  Settings
                </button> */}
                <button
                  className="text-[#E3270E] h-8 text-left text-base w-full"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {showChangePassword && (
          <ChangePassword onClose={() => setShowChangePassword(false)} user={user} />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {logoutConfirm && (
          <LogoutConfirm
            onCancel={() => setLogoutConfirm(false)}
            onConfirm={handleLogoutConfirm}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {settingsModal && <SettingsModal onClose={handleSettingsClose} />}
      </AnimatePresence>
    </>
  );
}
