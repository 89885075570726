import React, { useEffect, useMemo, useState } from "react";
import { ClientReferral } from "../ClientReferrals.types";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

export type ClientReferralsProviderProps = {
  clientReferrals: Array<ClientReferral>;
  refreshReferrals: () => {}
}

export const ClientReferralContext = React.createContext<ClientReferralsProviderProps>({
  clientReferrals: [],
  refreshReferrals: () => ({}),
});

export function ClientReferralProvider({ children }) {
  const { myPermissions } = useAdminUsers();
  const user = getUser();
  const [clientReferrals, setClientReferrals] = useState<Array<ClientReferral>>([]);

  const fetchData = useMemo(() => async () => {
    if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_REFERRALS)) {
      setClientReferrals(await api.getClientReferrals())
    }
  }, [myPermissions]);

  useEffect(() => {
    if (user?.id) {
      fetchData();
    }
  }, [user?.id, myPermissions]);

  const values = useMemo(() => ({
    clientReferrals: clientReferrals,
    refreshReferrals: fetchData,
  }), [clientReferrals, fetchData]);

  return <ClientReferralContext.Provider value={values}>{children}</ClientReferralContext.Provider>;
}

export function useClientReferrals() {
  const context = React.useContext<ClientReferralsProviderProps>(ClientReferralContext);
  if (context === undefined) {
    throw new Error("useClientReferrals must be used within a ClientReferralProvider");
  }
  return context;
}
