import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading/Loading";
import { buildURI } from "../../../services/csv-generator";
import { ShowToast } from "../../../services/toast";
import { useAuthRequests } from "../context/RequestAuthContext";
import { AuthRequest, AuthRequestStatus, authRequestStatusColor, authRequestStatusText } from "../RequestAuth.types";
import { AuthRequestModal } from "../modals/AuthRequestModal";
import Modal from "../../../components/Modal";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";
import { SelectInput } from "../../../components/SelectInput";


export function AuthRequestList({ inactiveList, onClose, onRefresh }: { inactiveList?: boolean, onClose?: () => void, onRefresh?: () => void }) {
  const { authRequests, refreshRequests } = useAuthRequests();
  const [requestView, setRequestView] = useState<AuthRequest>();
  const [showInactiveList, setShowInactiveList] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)
  const [stateFilter, setStateFilter] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    refreshRequests();
  }, []);

  const sortedRequests = authRequests.sort((a, b) => {

    // default
    if (!sortField && a.createdAt && b.createdAt) {
      return a.createdAt < b.createdAt ? 1 : -1;
    }

    if (sortField === 'owner' && a.provider?.firstName && b.provider?.firstName) {
      const aName = `${a.provider.firstName} ${a.provider.lastName}`;
      const bName = `${b.provider.firstName} ${b.provider.lastName}`;
      return aName.localeCompare(bName) * (sortASC ? 1 : -1)
    }
    if (sortField === 'friendlyId' && a.friendlyId && b.friendlyId) {
      return (sortASC ? (a.friendlyId < b.friendlyId) : (b.friendlyId < a.friendlyId)) ? -1 : 1;
    }
    if ((sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }
    if (sortField === 'client' && a.client?.firstName && b.client?.firstName) {
      const aName = `${a.client.firstName} ${a.client.lastName}`;
      const bName = `${b.client.firstName} ${b.client.lastName}`;
      return aName.localeCompare(bName) * (sortASC ? 1 : -1)
    }
    if (sortField === 'clientZipCode' && a.patientInfo?.zipCode && b.patientInfo?.zipCode) {
      return (sortASC ? (a.patientInfo?.zipCode < b.patientInfo?.zipCode) : (b.patientInfo?.zipCode < a.patientInfo?.zipCode)) ? -1 : 1;
    }
    if (sortField === 'type' && a.type && b.type) {
      return (sortASC ? (a.type < b.type) : (b.type < a.type)) ? -1 : 1;
    }

    if (sortField === 'pointPerson') {
      if (a.pointPerson && b.pointPerson) {
        return a.pointPerson.localeCompare(b.pointPerson) * (sortASC ? 1 : -1)
      } else {
        if (a.pointPerson && !b.pointPerson) {
          return (sortASC ? -1 : 1)
        } else if (b.pointPerson && !a.pointPerson) {
          return (sortASC ? 1 : -1)
        }
      }
    }
    if (sortField === 'status' && a.status && b.status) {
      const keys = Object.values(AuthRequestStatus);
      return ((keys.indexOf(a.status) < keys.indexOf(b.status)) ? -1 : 1) * (sortASC ? 1 : -1)
    }
    return 0;
  }).filter(request => {
    if (!searchFilter || searchFilter.length === 0) return true;
    if (`${request.client?.firstName} ${request.client?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (`${request.provider?.firstName} ${request.provider?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (request.friendlyId?.includes(searchFilter)) return true;
    if (request.patientInfo?.zipCode?.includes(searchFilter)) return true;
    return false
  })

  const downloadList = async () => {
    try {
      setIsDownloading(true);
      const data = authRequests?.filter(r => inactiveList ? !r.isActive : r.isActive)?.map((request) => {
        return [
          `${request?.provider?.firstName} ${request?.provider?.lastName}`,
          `${request.client?.firstName} ${request?.client?.lastName}`,
          request?.type ?? '-',
          request.friendlyId,
          moment.utc(request.createdAt).local().format("MMM DD,YYYY"),
          request?.pointPerson ?? '-',
          request?.status ?? '-',
        ];
      });
      data.splice(0, 0, [
        "Provider Name",
        "Client Name",
        "Auth Type",
        "Request #",
        "Submitted Date",
        "Headstart Point Person",
        "Status",
      ]);
      const build = buildURI(data, true, undefined, undefined, undefined);
      // download csv file
      const a = document.createElement("a");
      a.href = build;
      a.download = `${inactiveList ? 'inactive-' : ''}auth-requests-${moment().format()}.csv`;
      a.click();
      // remove the a tag
      a.remove();
      setIsDownloading(false);
    } catch (error: any) {
      setIsDownloading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortField(undefined)
        setSortASC(true)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "owner", label: "Provider Name" },
    { value: "client", label: "Client Name" },
    { value: "clientZipCode", label: "Client Zip Code" },
    { value: "type", label: "Auth Type" },
    { value: "friendlyId", label: "Request #" },
    { value: "createdAt", label: "Submitted Date" },
    { value: "pointPerson", label: "Headstart Point Person" },
    { value: "status", label: "Status" },
  ]

  const allRequestsStateData = sortedRequests?.filter(request => request.isActive && request?.patientInfo?.zipCode)?.map((request) =>
  ({
    value: zipCodes[request?.patientInfo?.zipCode ?? '']?.state_short,
    label: zipCodes[request?.patientInfo?.zipCode ?? '']?.state
  }));
  const statesShown = allRequestsStateData?.filter((state, index) => state.value && allRequestsStateData.findIndex(s => s.value === state.value) === index);


  const page = <LoadAnimatedContainer>
    <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
      <div className="text-black text-3xl font-bold">{inactiveList ? "Inactive" : "Manage"} Authorization Requests</div>
      <div className="flex justify-center"></div>
    </div>
    <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
            <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
            <input
              type="text"
              placeholder="Search"
              className="outline-none"
              onChange={(e) => setSearchFilter(e.target.value || '')}
            />
          </div>
          <SelectInput
            placeholder="Filter by state"
            disabledOptionText="Filter by state"
            data={stateFilter === '' ? statesShown : [{ label: "No Filter", value: '' }, ...statesShown]}
            value={stateFilter ?? ''}
            onChange={(e) => setStateFilter(e.target.value)}
            className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
          />
        </div>


        <div className="flex gap-2 items-center ml-auto">

          {!inactiveList &&
            <button
              onClick={() => setShowInactiveList(true)}
              className="pl-4 pr-3.5 py-3 group rounded-full justify-start items-center flex"
            >
              <div className="text-black text-base group-hover:text-black/80">
                View Inactive Requests
              </div>
              <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
            </button>
          }
          <Button
            variant="link"
            onClick={() => downloadList()}
            disabled={isDownloading}
            className="border rounded-md !px-[14px] !py-2 border-primary hover:opacity-80 [&>svg]:!w-6 [&>svg]:!h-6 [&>svg]:!mr-0"
          >
            {isDownloading ? <Loading /> : <DownloadIcon />}
          </Button>
        </div>
      </div>
      <div
        className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
      >
        <table className="w-full">
          <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
            <tr className="sticky top-0 z-[1]">
              {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                <div className="flex items-center cursor-pointer" onClick={sortToggleClick(header.value)}>
                  {header.label}
                  {sortField === header.value && <div>
                    {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                  </div>}
                </div>
              </td>))}
            </tr>
          </thead>
          <tbody>
            {sortedRequests?.filter(request => !stateFilter || (request?.patientInfo?.zipCode && zipCodes[request?.patientInfo?.zipCode]?.state_short === stateFilter))?.filter(r => inactiveList ? !r.isActive : r.isActive)?.map((request) => (
              <tr
                key={request.id}
                className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                onClick={() => setRequestView(request)}
              >
                <td className="name pl-5">
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2.5">
                        <div className="inline-block overflow-none">
                          {request?.provider?.firstName} {request?.provider?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="name">
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2.5">
                        <div className="inline-block overflow-none">
                          {request?.client?.firstName} {request?.client?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {request?.patientInfo?.zipCode ?? ''} {request?.patientInfo?.zipCode && zipCodes?.[request?.patientInfo?.zipCode]?.city ? `(${zipCodes?.[request?.patientInfo?.zipCode]?.city},${zipCodes?.[request?.patientInfo?.zipCode]?.state_short})` : ''}
                </td>
                <td>
                  {!request?.type ? '-' : `${request?.type.charAt(0).toUpperCase()}${request?.type.slice(1).toLowerCase()} Authorization`}
                </td>
                <td className="">
                  <div className="flex items-center gap-2">
                    <div className="font-bold">{request.friendlyId}</div>
                  </div>
                </td>
                <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                  {moment.utc(request.createdAt).local().format("MMM DD,YYYY")}
                </td>

                <td>
                  {request?.pointPerson ? (request.pointPerson.length === 0 ? '-' : request.pointPerson) : '-'}
                </td>

                <td>
                  <div style={{ color: authRequestStatusColor(request.status || AuthRequestStatus.REVIEWING) }}>
                    {authRequestStatusText(request.status)}
                  </div>
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>
    {requestView && <AuthRequestModal authRequestId={requestView?.id} onClose={
      () => {
        setRequestView(undefined);
        refreshRequests();
      }}
    />}
    {showInactiveList && (
      <AuthRequestList
        inactiveList={showInactiveList}
        onClose={() => {
          setShowInactiveList(false)
        }}
        onRefresh={refreshRequests}
      />
    )}
  </LoadAnimatedContainer >

  return inactiveList ?
    <Modal isOpen={true} handleModal={onClose}>
      {page}
    </Modal> : page

}

