import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useRbtRequests } from "../context/RbtRequestContext";
import { useEffect, useState } from "react";
import moment from "moment";
import { InactiveRbtRequestList } from "./InactiveRbtRequestList";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading/Loading";
import { RbtRequest, RbtRequestStatus, rbtRequestStatusColor } from "../RequestRbt.types";
import { buildURI } from "../../../services/csv-generator";
import { ShowToast } from "../../../services/toast";
import { RbtRequestModal } from "../modals/RbtRequestModal";
import { zipCodes } from "../modals/zip_codes";
import { SelectInput } from "../../../components/SelectInput";


export function RbtRequestList() {
  const { rbtRequests, refreshRequests } = useRbtRequests();
  const [requestView, setRequestView] = useState<RbtRequest>();
  const [showInactiveList, setShowInactiveList] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)
  const [stateFilter, setStateFilter] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    refreshRequests();
  }, []);

  const sortedRequests = rbtRequests.sort((a, b) => {

    // default
    if ((!sortField) && a.createdAt && b.createdAt) {
      return a.createdAt < b.createdAt ? -1 : 1;
    }

    if (sortField === 'owner' && a.owner?.firstName && b.owner?.firstName) {
      return a.owner.firstName.localeCompare(b.owner.firstName) * (sortASC ? 1 : -1)
    }
    if (sortField === 'friendlyId' && a.friendlyId && b.friendlyId) {
      return (sortASC ? (a.friendlyId < b.friendlyId) : (b.friendlyId < a.friendlyId)) ? -1 : 1;
    }
    if (sortField === 'createdAt' && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }
    if (sortField === 'desiredStart' && a.desiredStart && b.desiredStart) {
      return (sortASC ? (a.desiredStart < b.desiredStart) : (b.desiredStart < a.desiredStart)) ? -1 : 1;
    }
    if (sortField === 'clientZipCode' && a.clientZipCode && b.clientZipCode) {
      return (sortASC ? (a.clientZipCode < b.clientZipCode) : (b.clientZipCode < a.clientZipCode)) ? -1 : 1;
    }
    if (sortField === 'pointPerson') {
      if (a.pointPerson && b.pointPerson) {
        return a.pointPerson.localeCompare(b.pointPerson) * (sortASC ? 1 : -1)
      } else {
        if (a.pointPerson && !b.pointPerson) {
          return (sortASC ? -1 : 1)
        } else if (b.pointPerson && !a.pointPerson) {
          return (sortASC ? 1 : -1)
        }
      }
    }
    if (sortField === 'status' && a.status && b.status) {
      const keys = Object.values(RbtRequestStatus);
      return ((keys.indexOf(a.status) < keys.indexOf(b.status)) ? -1 : 1) * (sortASC ? 1 : -1)
    }
    return 0;
  }).filter(request => {
    if (!searchFilter || searchFilter.length === 0) return true;
    if (request.rbtCandidates.map(c => `${c.firstName} ${c.lastName}`).join(" ")?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (`${request.owner?.firstName} ${request.owner?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (request.friendlyId?.includes(searchFilter)) return true;
    if (request.clientZipCode?.includes(searchFilter)) return true;
    return false
  })


  const allRequestsStateData = sortedRequests?.filter(request => request.isActive && request.clientZipCode)?.map((request) =>
  ({
    value: zipCodes[request?.clientZipCode]?.state_short,
    label: zipCodes[request.clientZipCode]?.state
  }));
  const statesShown = allRequestsStateData?.filter((state, index) => state.value && allRequestsStateData.findIndex(s => s.value === state.value) === index);

  const downloadList = async () => {
    try {
      setIsDownloading(true);
      const data = rbtRequests?.filter(r => r.isActive)?.map((request) => {
        return [
          `${request?.owner?.firstName} ${request?.owner?.lastName}`,
          request.friendlyId,
          moment.utc(request.createdAt).local().format("MMM DD,YYYY"),
          moment.utc(request.desiredStart).local().format("MMM DD,YYYY"),
          request?.pointPerson ?? '-',
          request?.status ?? '-',
          (!request?.rbtCandidates || request?.rbtCandidates?.length === 0) ? "-" :
            `${request.rbtCandidates.map(c => `${c.firstName} ${c.lastName}`).join(", ")}`,
        ];
      });
      data.splice(0, 0, [
        "Provider Name",
        "Request #",
        "Submitted Date",
        "Target Start Date",
        "Point Person",
        "Status",
        "Candidate Assigned",
      ]);
      const build = buildURI(data, true, undefined, undefined, undefined);
      // download csv file
      const a = document.createElement("a");
      a.href = build;
      a.download = `rbt-requests-${moment().format()}.csv`;
      a.click();
      // remove the a tag
      a.remove();
      setIsDownloading(false);
    } catch (error: any) {
      setIsDownloading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortField(undefined)
        setSortASC(true)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "owner", label: "Provider Name" },
    { value: "friendlyId", label: "Request #" },
    { value: "createdAt", label: "Submitted Date" },
    { value: "desiredStart", label: "Target Start Date" },
    { value: "clientZipCode", label: "Client Zip Code" },
    { value: "pointPerson", label: "Headstart Point Person" },
    { value: "status", label: "Status" },
    { value: "candidate", label: "Candidate Assigned" }
  ]

  return (
    <>
      <LoadAnimatedContainer>
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <div className="text-black text-3xl font-bold">Manage RBT Requests</div>
          <div className="flex justify-center"></div>
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => setSearchFilter(e.target.value || '')}
                />
              </div>
              <SelectInput
                placeholder="Filter by state"
                disabledOptionText="Filter by state"
                data={stateFilter === '' ? statesShown : [{ label: "No Filter", value: '' }, ...statesShown]}
                value={stateFilter ?? ''}
                onChange={(e) => setStateFilter(e.target.value)}
                className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
              />
            </div>


            <div className="flex gap-2 items-center ml-auto">

              <button
                onClick={() => setShowInactiveList(true)}
                className="pl-4 pr-3.5 py-3 group rounded-full justify-start items-center flex"
              >
                <div className="text-black text-base group-hover:text-black/80">
                  View Inactive Requests
                </div>
                <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
              </button>
              <Button
                variant="link"
                onClick={() => downloadList()}
                disabled={isDownloading}
                className="border rounded-md !px-[14px] !py-2 border-primary hover:opacity-80 [&>svg]:!w-6 [&>svg]:!h-6 [&>svg]:!mr-0"
              >
                {isDownloading ? <Loading /> : <DownloadIcon />}
              </Button>
            </div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                </tr>
              </thead>
              <tbody>
                {sortedRequests?.filter(request => !stateFilter || zipCodes[request?.clientZipCode]?.state_short === stateFilter)?.filter(r => r.isActive)?.map((request) => (
                  <tr
                    key={request.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setRequestView(request)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2.5">
                            <div className="inline-block overflow-none">
                              {request?.owner?.firstName} {request?.owner?.lastName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex items-center gap-2">
                        <div className="font-bold">{request.friendlyId}</div>
                      </div>
                    </td>
                    <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                      {moment.utc(request.createdAt).local().format("MMM DD,YYYY")}
                    </td>
                    <td>
                      {moment.utc(request.desiredStart).local().format("MMM DD,YYYY")}
                    </td>
                    <td>
                      {request.clientZipCode ?? ''} {zipCodes?.[request?.clientZipCode]?.city ? `(${zipCodes?.[request?.clientZipCode]?.city},${zipCodes?.[request?.clientZipCode]?.state_short})` : ''}
                    </td>
                    <td>
                      {request?.pointPerson ?? '-'}
                    </td>

                    <td>
                      <div style={{ color: rbtRequestStatusColor(request.status || RbtRequestStatus.REVIEW) }}>
                        {request.status}
                      </div>
                    </td>
                    <td>
                      {(!request?.rbtCandidates || request?.rbtCandidates?.length === 0) ? "-" :
                        `${request.rbtCandidates.map(c => `${c.firstName} ${c.lastName}`).join(", ")}`}
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
        {requestView && <RbtRequestModal rbtRequest={requestView} onClose={
          () => {
            setRequestView(undefined);
            refreshRequests();
          }}
        />}
        {showInactiveList && (
          <InactiveRbtRequestList
            isOpen={showInactiveList}
            onClose={() => {
              setShowInactiveList(false)
            }}
            onRefresh={refreshRequests}
          />
        )}
      </LoadAnimatedContainer >
    </>
  );
}

