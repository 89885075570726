import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import Autosuggest from 'react-autosuggest';
import React, { useEffect, useState } from "react";
import { DeleteRbtCandidateConfirm } from "./CancelConfirmRbtCandidate";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { PopupModal } from "../../../components/PopupModal";
import { SelectInput } from "../../../components/SelectInput";
import { DatePicker } from "../../../components/DatePicker";
import { Button } from "../../../components/Button";
import { rbtCandidateStatusToSentenceCase, getRbtCandidateStatusByValue, RbtCandidate, RbtCandidateStatus, rbtCandidateStatusColor } from "../RbtCandidate.types";
import { useRbtRequests } from "../../rbtRequests/context/RbtRequestContext";
import { RbtRequest } from "../../rbtRequests/RequestRbt.types";
import "./RbtAssignModal.styles.css";
import moment from "moment";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";


export function RbtAssignModal({ rbtCandidate, onViewProfile, onClose }: { rbtCandidate: RbtCandidate; onViewProfile: () => void; onClose: () => void }) {
  const { myPermissions } = useAdminUsers();
  const [form, setForm] = useState<RbtCandidate>(rbtCandidate);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [editMode, setEditMode] = useState(true)
  const [deleteRequestId, setDeleteRequest] = useState<RbtCandidate | undefined>()
  const [changeMade, setChangeMade] = useState(false)
  const { rbtRequests } = useRbtRequests()
  const [autoSuggestRequests, setAutoSuggestRequests] = useState<Array<RbtRequest> | undefined>()

  useEffect(() => {
    setEditMode(myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_RBT))
  }, [myPermissions])

  const assignedRequest = rbtRequests.filter(r => r.friendlyId === form.assignedRequestId)?.[0] ?? undefined;

  const getRequestSuggestions = value => {
    const inputValue = value?.trim()?.toLowerCase() ?? '';
    const inputLength = inputValue.length;
    if (inputLength === 0) return []

    const withNum = rbtRequests.filter(request => request.isActive &&
      request?.friendlyId?.indexOf(`${inputValue}`) !== -1
    )
    withNum.sort((a, b) => a?.friendlyId?.localeCompare(b?.friendlyId ?? '') ?? 0)

    return withNum.slice(0, 5)

  };


  React.useEffect(() => {
    // check for scroll event and set scroll value in state
    const onScroll = (e) => {
      setIsScrolled(e.target.scrollTop > 200);
    };
    const element = document.getElementById("provider-scroll");
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, []);


  const handleCancel = async () => {
    if (rbtCandidate?.id) {
      setDeleteRequest(rbtCandidate)
    }
  }

  const handleSubmit = async () => {
    let newErrors = {}

    if (form.assignedRequestId && !assignedRequest) {
      newErrors = { ...newErrors, assignedRequestId: "Please provide either a valid Request # or leave Request # field blank" }
    }

    if (form.assignedRequestId && form?.status === RbtCandidateStatus.UNASSIGNED) {
      newErrors = { ...newErrors, status: "Please select a different status (or remove assigned request #)" }
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors)
      return
    }

    setErrors({})
    setLoading(true)


    const response = await api.updateRbtCandidate(rbtCandidate?.id, form);
    if ([200, 201].includes(response.status)) {
      ShowToast({
        message: `You've successfully updated Candidate Assignment`,
        type: "success",
      });
    } else {
      ShowToast({
        message: response?.data?.message || "Something went wrong",
        type: "error",
      })
    }
    onClose();

    setLoading(false)
  };

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7 text-center">
            RBT Candidate Status
            {rbtCandidate?.status && <div className="inline-block ml-5 px-2 h-5 rounded-lg gap-2.5" style={{ transform: "translateY(-3px)", backgroundColor: rbtCandidateStatusColor(rbtCandidate.status) }}>
              <div className="text-white text-sm font-normal">{rbtCandidateStatusToSentenceCase(rbtCandidate.status)}</div>
            </div>}
          </div>
          <div className="inline-block text-primary cursor-pointer mt-1"
            onClick={onViewProfile}>View Candidate Profile</div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-155px)] overflow-auto" id="provider-scroll">
          <div className="flex flex-col mb-5">
            <span className="text-black/80 text-center">Candidate Name: {rbtCandidate?.firstName} {rbtCandidate?.lastName}</span>

            {rbtCandidate.rejections && rbtCandidate.rejections.length > 0 && <>
              Rejection History:
              {rbtCandidate.rejections.sort((a, b) => new Date(b.rejectionDate).getTime() - new Date(a.rejectionDate).getTime()).map((rejection, index) => (<div key={index} className="">
                <div className="font-normal text-sm text-slate-500">
                  {moment.utc(rejection.rejectionDate).local().format("MMM DD,YYYY")}: {rejection.hadOffer === true ? "Unsigned" : "Rejected"} by {rejection.rbtRequestOwner?.firstName} {rejection.rbtRequestOwner?.lastName} (#{rbtRequests.filter(request => request.id === rejection.rbtRequestId)[0]?.friendlyId}):
                  "{rejection.rejectReason}"
                </div>
              </div>))}
            </>}

          </div>


          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Status</span>
              <SelectInput
                data={Object.entries(RbtCandidateStatus).map(([k, v]) => ({ value: k, label: rbtCandidateStatusToSentenceCase(v) }))}
                name="status"
                value={form?.status}
                disabledOptionText={"Select Status"}
                onChange={(e) => {
                  setForm({ ...form, status: getRbtCandidateStatusByValue(e.target.value) as RbtCandidateStatus });
                  setChangeMade(true)
                }}
                className={`h-10 py-0 ${!editMode && 'text-gray-500'}`}
                disabled={!editMode}
              />
              {errors.status && (
                <div className="text-red-500 text-xs font-medium mt-2">
                  {errors.status}
                </div>
              )}
            </div>
            <div className="flex flex-col mb-5"></div>
          </div>


          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Assigned To</span>
              <div className="inline-flex">
                <div className="whitespace-nowrap mr-1 md:mx-5 pt-1">Request #</div>

                <Autosuggest
                  suggestions={autoSuggestRequests?.filter(r => r.isActive) || []}
                  onSuggestionsFetchRequested={({ value }) => setAutoSuggestRequests(getRequestSuggestions(value))}
                  getSuggestionValue={(request) => request?.friendlyId}
                  renderSuggestion={(request) => `${request?.owner?.firstName} ${request?.owner?.lastName}: #${request?.friendlyId}`}
                  onSuggestionSelected={(e, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
                    if (suggestionValue) {
                      setForm({ ...form, assignedRequestId: suggestionValue })
                    }
                  }}
                  inputProps={{
                    disabled: !editMode,
                    className: "pl-3 py-1 !outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 text-base text-black [&>div]:!border-none",
                    placeholder: 'Request #',
                    value: form?.assignedRequestId ?? '',
                    onChange: (e, { newValue, method }) => {

                      if ((/^\d+$/.test(newValue.trim()) && newValue.trim()?.length <= 8) || newValue.trim() === "" || newValue === '') {
                        setForm({ ...form, assignedRequestId: newValue.trim() })
                        setChangeMade(true)
                        setErrors({ ...errors, assignedRequestId: undefined })
                      }
                      if (newValue.trim() === "" || newValue === '') {
                        setForm((form) => ({ ...form, reviewDeadline: undefined }))
                      }
                    }
                  }}
                />
              </div>
              {errors.assignedRequestId && (
                <div className="text-red-500 text-xs font-medium mt-2">
                  {errors.assignedRequestId}
                </div>
              )}
              {assignedRequest?.owner?.firstName && <div className="ml-5 mt-2">BCBA: <span className="ml-12">{assignedRequest?.owner?.firstName} {assignedRequest?.owner?.lastName}</span></div>}

            </div>
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Review Deadline</span>
              <DatePicker
                className="w-max p-0"
                onChange={(value) => {
                  setForm({ ...form, reviewDeadline: value });
                  setChangeMade(true)
                }}
                value={form.reviewDeadline}
                minDate={new Date()}
                height="20px"
                disabled={!editMode || !form.assignedRequestId}
              />
              {errors.reviewDeadline && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.reviewDeadline}
                </div>
              )}
            </div>
          </div>


        </div>

        {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_RBT) &&
          <div className="w-full">
            {changeMade ?
              <div className="grid grid-cols-2 gap-10 px-1">
                <Button
                  disabled={loading}
                  variant="outline"
                  className="!rounded-full w-full mt-0 text-white"
                  onClick={onClose}
                  loading={loading}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading || !changeMade}
                  variant={(editMode && !changeMade) ? "outline" : "primary"}
                  className="!rounded-full w-full mt-0"
                  onClick={() => editMode ? ((changeMade) ? handleSubmit() : setEditMode(false)) : setEditMode(!editMode)}
                  loading={loading}
                >
                  Save
                </Button>
              </div>
              : <div></div>
            }
          </div>
        }



        {deleteRequestId && <DeleteRbtCandidateConfirm
          onCancel={() => setDeleteRequest(undefined)}
          onDelete={onClose}
          candidate={deleteRequestId} />}

      </>
    </PopupModal >
  );
}
