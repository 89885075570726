import { useCallback, useEffect, useState } from "react";
import { api } from "../../../services/api";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { getUser } from "../../../services/utils";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

export type Bulletin = {
  id: string;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt?: Date;
  isActive: boolean;
  previewImg?: string;
  attachedDocs?: Array<{ id: string; name: string; type: "youtubelink" | "file" | "folder"; url?: string }>;
};

function useBulletins() {
  const user = getUser();
  const { myPermissions } = useAdminUsers();
  const [bulletins, setBulletins] = useState<Array<Bulletin>>([]);

  const fetchBulletins = useCallback(async () => {
    if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_BULLETIN)) {
      setBulletins(await api.getBulletins());
    }
  }, [myPermissions]);

  useEffect(() => {
    fetchBulletins();
  }, [user.id, myPermissions, fetchBulletins]);

  return {
    bulletins,
    fetchBulletins,
  };
}

export default useBulletins;
