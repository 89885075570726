import axios from "axios";
import { PopupModal } from "../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";

import React from "react";
import { ImageView } from "../../../components/Image";
import { Loading } from "../../../components/Loading/Loading";

const getFileName = (url: string) => {
  const urlParts = url.split("/");
  // without . extension
  const fileName = urlParts[urlParts.length - 1].split(".")[0];
  return fileName;
};

const getDownloadFileName = (url: string) => {
  const urlParts = url.split("/");
  // without . extension
  const fileName = urlParts[urlParts.length - 1];
  return fileName;
};

export function FileMessage({
  contentType,
  content,
  isMe,
}: {
  contentType: string;
  content: string;
  isMe: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const downloadFile = async () => {
    setDownloading(true);
    const url = content;
    const fileName = getDownloadFileName(url);
    if (url) {
      // download file here
      const response = await axios.get(url, {
        responseType: "blob",
      });
      const newURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = newURL;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(newURL);
    }
    setDownloading(false);

  };
  const handleDownload = async () => {
    if (
      contentType.includes("image") ||
      contentType.includes("pdf") ||
      contentType.includes("video") ||
      contentType.includes("audio")
    ) {
      setOpen(true);
      return;
    }
    downloadFile();
  };

  return (
    <div
      onClick={handleDownload}
      role="button"
      className={`rounded-lg p-2.5 cursor-default ${isMe ? "bg-emerald-50" : "border border-zinc-300"
        } text-black text-base font-light font-['Outfit'] flex gap-2.5`}
    >
      <>
        {contentType.includes("pdf") ? (
          <div className="w-12 h-12 flex items-center gap-2">
            <ImageView
              src="/images/pdf.png"
              alt="document"
              className="w-full h-full rounded-lg object-cover"
            />
          </div>
        ) : contentType.includes("image") ? (
          <div className="w-12 h-12">
            <ImageView
              src={content}
              className="w-full h-full object-cover rounded-lg"
              alt="content"
            />
          </div>
        ) : contentType.includes("audio") ? (
          <audio className="w-12 h-12" src={content} controls />
        ) : contentType.includes("video") ? (
          <video className="w-12 h-12" src={content} controls />
        ) : (
          <div className="w-12 h-12 flex items-center gap-2">
            <FileIcon />
          </div>
        )}
      </>
      <div className="flex-1 flex flex-col">
        <span
          className="text-ellipsis overflow-hidden whitespce-nowrap"
          style={{ wordBreak: "break-word" }}
        >
          {getFileName(content)}
        </span>
        <span className="uppercase">
          {contentType?.split("/")[1] || contentType?.split("/")[0]}
        </span>
      </div>
      {open && (
        <PopupModal
          contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center !gap-4 flex w-[98%] md:w-[60vw] mx-auto min-h-[80vh] max-h-[calc(100vh-50px)]"
          onClose={() => setOpen(false)}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2" onClick={() => downloadFile()}>
              <div
                className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap pointer-cursor"
                style={{ wordBreak: "break-word" }}
              >
                {downloading ?
                  getFileName(content)
                  :
                  <button>{getFileName(content)}</button>
                }
              </div>
              {downloading ?
                <Loading />
                :
                <button>
                  <DownloadIcon />
                </button>
              }

            </div>
            <button onClick={() => setOpen(false)}>
              <CloseIcon className="w-8 h-8" />
            </button>
          </div>
          {
            // if content type is image
            contentType.includes("image") ? (
              <div className="flex justify-center items-center flex-1 w-full">
                <div className="h-[calc(100vh-200px)]">
                  <ImageView
                    src={content}
                    className="w-full h-full object-contain"
                    alt="file"
                  />
                </div>
              </div>
            ) : (
              <iframe
                src={content}
                className="w-full h-full object-contain"
                title="file"
                frameBorder="0"
              />
            )
          }
        </PopupModal>
      )}
    </div>
  );
}
