import React, { useEffect, useMemo, useState } from "react";
import { getUser } from "../../../services/utils";
import { RbtRequest } from "../RequestRbt.types";
import { api } from "../../../services/api";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

export const RBTRequestContext = React.createContext<{ rbtRequests: Array<RbtRequest>; refreshRequests: () => {} }>({
  rbtRequests: [],
  refreshRequests: () => ({}),
});

export function RbtRequestProvider({ children }) {
  const { myPermissions } = useAdminUsers();
  const user = getUser();
  const [requests, setRequests] = useState<Array<RbtRequest>>([]);

  const fetchRequests = useMemo(
    () => async () => {
      if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_RBT)) {
        setRequests(await api.adminGetAllRbtRequests());
      }
    },
    [myPermissions]
  );

  useEffect(() => {
    if (user?.id) {
      fetchRequests();
    }
  }, [user?.id, myPermissions]);

  const values = useMemo(
    () => ({
      rbtRequests: requests,
      refreshRequests: fetchRequests,
    }),
    [requests, fetchRequests]
  );

  return <RBTRequestContext.Provider value={values}>{children}</RBTRequestContext.Provider>;
}

export function useRbtRequests() {
  const context = React.useContext<{ rbtRequests: Array<RbtRequest>; refreshRequests: () => {} }>(RBTRequestContext);
  if (context === undefined) {
    throw new Error("useRbtRequests must be used within a RBTRequestProvider");
  }
  return context;
}
