import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useState } from "react";
import { ArchiveAuthRequestConfirm } from "./CancelConfirmAuthRequest";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { PopupModal } from "../../../components/PopupModal";
import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import { ReActivateAuthRequestConfirm } from './ReActivateConfirmAuthRequest';
import { useClientReferrals } from '../context/ClientReferralContext';
import { ClientReferral, ClientReferralStatus, clientReferralStatusColor, getClientReferralStatusByValue } from '../ClientReferrals.types';
import { SelectInput } from "../../../components/SelectInput";
import { ImageView } from "../../../components/Image";
import axios from "axios";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

export function ClientReferralModal({ clientReferralId, onClose }: { clientReferralId?: string; onClose: () => void }) {
  const { myPermissions } = useAdminUsers();
  const { clientReferrals, refreshReferrals } = useClientReferrals();
  const [form, setForm] = useState<ClientReferral>({
    ...clientReferrals.filter(r => r.id === clientReferralId)[0]!
  }
  );
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [archiveRequest, setArchiveRequest] = useState<ClientReferral | undefined>()
  const [reactivateRequest, setReactivateRequest] = useState<ClientReferral | undefined>()
  const [changeMade, setChangeMade] = useState(false)
  const [previewFile, setPreviewFile] = useState<{ url: string, fileName: string } | undefined>();

  const clientReferral = clientReferrals.filter(r => r.id === clientReferralId)[0]!

  const handleArchive = async () => {
    if (clientReferralId) {
      setArchiveRequest(clientReferral)
    }
  }

  const handleReActivate = async () => {
    if (clientReferralId) {
      setReactivateRequest(clientReferral)
    }
  }

  const handleSubmit = async () => {
    let newErrors = {}
    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors)
      return
    }
    setErrors({})
    setLoading(true)
    const response = await api.updateClientReferral(form)
    if ([200, 201].includes(response.status)) {
      ShowToast({
        message: "You've successfully updated the Client Referral",
        type: "success",
      });
      refreshReferrals();
    } else {
      ShowToast({
        message: response?.data?.message || "Something went wrong",
        type: "error",
      })
    }
    setLoading(false)
  };

  const handlePreviewFileDownload = async () => {
    // download file instead of opening it
    const url = previewFile?.url;
    try {
      if (url) {
        // download file here
        const response = await axios.get(url, {
          responseType: "blob",
          headers: {
            Accept: "*",
          },
        });
        const newURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = newURL;
        link.setAttribute("download", `${previewFile.fileName}${decodeURIComponent(previewFile.url.substring(previewFile.url.lastIndexOf('.')))}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Unable to download file. Please try again.",
      });
    }
  }


  let cdeSection = "No Diagnostic Evaluation (DE) provided.";
  if (clientReferral.cdeUrl && clientReferral.cdeUrl.length > 0) {
    if (clientReferral.cdeUrl.endsWith(".pdf")) {
      cdeSection = `<a class="underline text-blue-600 hover:text-blue-800" href="${clientReferral.cdeUrl}">Diagnostic Evaluation PDF</a>`;
    } else {
      cdeSection = `<img src="${clientReferral.cdeUrl}" alt="Diagnostic Evaluation" style="width: 100%; height: auto;">`;
    }
  }

  const familyInformation = [
    { label: "Client Name", value: `${clientReferral.clientFirstName} ${clientReferral.clientLastName}` },
    { label: "Client State", value: clientReferral.clientState },
    { label: "Parent / Guardian Name", value: `${clientReferral.parentFirstName} ${clientReferral.parentLastName}` },
    { label: "Parent / Guardian Email", value: clientReferral.parentEmail },
    { label: "Parent / Guardian Phone Number", value: clientReferral.parentPhone },
  ]

  const referringProviderInformation = [
    { label: "Provider Name", value: `${clientReferral.providerFirstName} ${clientReferral.providerLastName}` },
    { label: "Provider Practice / Group Name", value: clientReferral.providerPracticeName },
    { label: "Provider Email", value: clientReferral.providerEmail },
    { label: "Provider Phone Number", value: clientReferral.providerPhone },
    { label: "Additional Information (Optional)", value: clientReferral.comments && clientReferral.comments.trim().length > 0 ? clientReferral.comments : "No additional information provided." },
  ]

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            Client Referral
            {clientReferral?.status && <div className="inline-block ml-5 px-2 h-5 rounded-lg gap-2.5" style={{ transform: "translateY(-3px)", backgroundColor: clientReferralStatusColor(form.status) }}>
              <div className="text-white text-sm font-normal">{form?.status}</div>
            </div>}
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-155px)] overflow-auto" id="provider-scroll">

          <div className="flex flex-col mb-5">

            <div className="mb-5">
              <span className="text-black/80 text-sm">Status</span>
              <SelectInput
                disabled={!myPermissions?.includes(AdminPermission.WRITE_PAGE_MANAGE_REFERRALS)}
                data={Object.entries(ClientReferralStatus).map(([k, v]) => ({ value: k, label: v }))}
                name="status"
                value={getClientReferralStatusByValue(form.status)}
                disabledOptionText={"Select Status"}
                onChange={(e) => {
                  setForm({ ...form, status: ClientReferralStatus[e.target.value] ?? undefined });
                  // setChangeMade(true)
                }}
                className={`h-10 py-0`}
              />
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-3 flex mt-3">
              <div className="text-neutral-500 text-sm font-semibold  uppercase">
                Family Information
              </div>
              <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5">
                {familyInformation.map(info => (
                  <div className="grid grid-cols-3">
                    <div className="w-full opacity-80 text-black text-sm font-normal ">
                      {info.label}:{" "}
                    </div>
                    <div className="col-span-2 w-full opacity-80 text-black text-sm font-medium ">
                      {info.value}
                    </div>
                    <div />
                  </div>))
                }
              </div>

              <div className="self-stretch flex-col justify-start items-start gap-3 flex  mt-3">
                <div className="text-neutral-500 text-sm font-semibold  uppercase">
                  Referring Provider Information
                </div>
                <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5">
                  {referringProviderInformation.map(info => (
                    <div className="grid grid-cols-3">
                      <div className="w-full opacity-80 text-black text-sm font-normal ">
                        {info.label}:{" "}
                      </div>
                      <div className="col-span-2 w-full opacity-80 text-black text-sm font-medium ">
                        {info.value}
                      </div>
                      <div />
                    </div>))
                  }
                </div>
              </div>

              <>
                <div className="text-neutral-500 text-sm font-semibold uppercase block mt-3">
                  Diagnostic Evaluation (DE)
                </div>
                <div
                  className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row"
                  onClick={() => clientReferral.cdeUrl && setPreviewFile({ url: clientReferral?.cdeUrl, fileName: `DiagnosticEvaluation` })}
                >
                  {(clientReferral.cdeUrl && clientReferral.cdeUrl.trim().length > 0) ?
                    <ImageView
                      className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                      containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                      src={clientReferral.cdeUrl.includes(".pdf") ? "/images/pdf.png" : clientReferral.cdeUrl}
                      style={clientReferral.cdeUrl.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                      alt="Diagnostic Evaluation"
                      loading="lazy"
                    />
                    : "No Diagnostic Evaluation (DE) provided."
                  }
                </div>
              </>

            </div>



            {(clientReferral && myPermissions?.includes(AdminPermission.WRITE_PAGE_MANAGE_REFERRALS)) && <>
              <div className="w-full">
                <div className="grid grid-cols-2 gap-10 px-1 mt-5">
                  {(clientReferral?.isActive ?
                    <Button
                      disabled={loading}
                      variant="primary"
                      className="!rounded-full w-full mt-0 bg-red-500 text-white"
                      onClick={handleArchive}
                      loading={loading}
                    >
                      Archive Referral
                    </Button>
                    :
                    <Button
                      disabled={loading}
                      variant="primary"
                      className="!rounded-full w-full mt-0 bg-red-500 text-white"
                      onClick={handleReActivate}
                      loading={loading}
                    >
                      Re-Activate Referral
                    </Button>
                  )}


                  <Button
                    disabled={loading || (form.status === clientReferral.status)}
                    variant={"primary"}
                    className="!rounded-full w-full mt-0"
                    onClick={() => handleSubmit()}
                    loading={loading}
                  >
                    Save
                  </Button>

                </div>
              </div>
            </>}

          </div>

          {archiveRequest && <ArchiveAuthRequestConfirm
            onCancel={() => setArchiveRequest(undefined)}
            onArchive={onClose}
            referral={archiveRequest} />}
          {reactivateRequest && <ReActivateAuthRequestConfirm
            onCancel={() => setReactivateRequest(undefined)}
            onDelete={onClose}
            referral={reactivateRequest} />
          }
        </div>
      </>

      {
        previewFile &&
        <PopupModal
          contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center !gap-4 flex w-[98%] md:w-[60vw] mx-auto min-h-[80vh] max-h-[calc(100vh-50px)]"
          onClose={() => setPreviewFile(undefined)}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2">
              <div
                className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                style={{ wordBreak: "break-word" }}
              >

              </div>
              <button onClick={handlePreviewFileDownload}>
                <DownloadIcon />
              </button>
            </div>
            <button onClick={() => setPreviewFile(undefined)}>
              <CloseIcon className="w-8 h-8" />
            </button>
          </div>

          <div className="flex justify-center items-center flex-1 w-full">

            {previewFile.url.includes(".pdf") ?
              <iframe
                src={previewFile.url}
                className="w-full h-full object-contain"
                title="file"
                frameBorder="0"
              />
              :
              <div className="h-[calc(100vh-200px)]">
                <ImageView
                  src={previewFile?.url}
                  className="w-full h-full object-contain"
                  alt="file"
                />
              </div>
            }

          </div>


        </PopupModal>
      }
    </PopupModal >);


}
