import { AdminPermission, FULL_PERMISSIONS, groupedPermissions } from '../AdminUserList.types';

export function PermissionsGrid({ permissions, setPermissions, disabled = false }: { permissions: AdminPermission[]; setPermissions: (permissions: AdminPermission[]) => void; disabled: boolean }) {

  return <>
    <div className={`${disabled ? 'bg-gray-100' : ''} rounded grid grid-cols-4 w-[70%] text-left outline outline-gray-300 mx-auto divide-x divide-y divide-solid divide-gray-300`}>
      <div className="grid col-span-2 pl-3">Permission Name</div>
      <div className="grid col-span-1 pl-3" style={{ borderTop: 'none' }}>Read Access
        {!disabled && <>
          <div className='inline-block pl-2'>
            <span className='text-primary inline-block cursor-pointer' onClick={() => {
              setPermissions([...permissions, ...Object.keys(groupedPermissions).filter(p => groupedPermissions[p].read).map(p => groupedPermissions[p].read)])
            }}>All</span>
            <span className='text-gray-300'> / </span>
            <span className='text-primary inline-block cursor-pointer' onClick={() => {
              const allWithReads = Object.keys(groupedPermissions).filter(p => groupedPermissions[p].read).flatMap(p => [groupedPermissions[p].read, groupedPermissions[p].write]) as AdminPermission[]
              setPermissions(permissions.filter(p => ![...allWithReads].includes(p)))
            }}>None</span>
          </div>
        </>}
      </div>
      <div className="grid col-span-1 pl-3" style={{ borderTop: 'none' }}>Full Control
        {!disabled && <>
          <div className='inline-block pl-2'>
            <span className='text-primary inline-block cursor-pointer' onClick={() => {
              setPermissions(FULL_PERMISSIONS)
            }}>All</span>
            <span className='text-gray-300'> / </span>
            <span className='text-primary inline-block cursor-pointer' onClick={() => {
              setPermissions(permissions.filter(p => !Object.keys(groupedPermissions).filter(p => groupedPermissions[p].write).map(p => groupedPermissions[p].write).includes(p)))
            }}>None</span>
          </div>
        </>}
      </div>

      {Object.keys(groupedPermissions).map((permissionName) => {
        const { read, write } = groupedPermissions[permissionName] as { read?: AdminPermission, write?: AdminPermission }
        return (
          <>
            <div className='grid col-span-2 text-sm pt-[4px] pl-3' style={{ borderLeft: 'none' }}>{permissionName}</div>
            {read ?
              <div className='text-center'>
                <input
                  className='w-[16px] h-[16px] pt-[4px]'
                  type="checkbox"
                  checked={permissions.includes(read)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPermissions([...permissions, read])
                    } else {
                      setPermissions(permissions.filter((p) => p !== read && p !== write))
                    }
                  }}
                  disabled={disabled}
                />
              </div> : <div />}
            {write ?
              <div className='text-center'>
                <input
                  className='w-[16px] h-[16px] pt-[4px]'
                  type="checkbox"
                  checked={permissions.includes(write)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newPermissions = read ? (permissions.includes(read) ? [...permissions, write] : [...permissions, read, write]) : [...permissions, write]
                      setPermissions(newPermissions)
                    } else {
                      setPermissions(permissions.filter((p) => p !== write))
                    }
                  }}
                  disabled={disabled}
                />
              </div>
              : <div />}
          </>
        )
      }
      )}
    </div>
  </>

}
