import React, { useEffect, useMemo, useState } from "react";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { AdminPermission, AdminUser } from "../AdminUserList.types";

export type AdminUsersProviderProps = {
  adminUsers: AdminUser[];
  myPermissions: AdminPermission[];
  refresh: () => {}
  fetchMyPermissions: () => {}
  initialPermissionsLoaded: boolean
}

export const AdminUsersContext = React.createContext<AdminUsersProviderProps>({
  adminUsers: [],
  myPermissions: [],
  refresh: () => ({}),
  fetchMyPermissions: () => ({}),
  initialPermissionsLoaded: false
});

export function AdminUsersProvider({ children }) {
  const user = getUser();

  const [adminUsers, setAdminUsers] = useState<Array<AdminUser>>([]);
  const [myPermissions, setMyPermissions] = useState<Array<AdminPermission>>([]);
  const [initialPermissionsLoaded, setInitialPermissionsLoaded] = useState(false);

  const fetchMyPermissions = useMemo(() => async () => {
    const newMyPermissions = (await api.getMyAdminPermissions()) as AdminPermission[];
    setMyPermissions(newMyPermissions)
    setInitialPermissionsLoaded(true)
  }, []);

  const fetchUsers = useMemo(() => async () => {
    if (myPermissions && myPermissions.includes(AdminPermission.MANAGE_ADMIN)) {
      setAdminUsers(await api.getAdminUsers())
    }
  }, [myPermissions]);

  const refresh = useMemo(() => async () => {
    fetchUsers();
    fetchMyPermissions();
  }, [fetchMyPermissions, fetchUsers])

  useEffect(() => {
    if (user?.id) {
      fetchMyPermissions();
    }
  }, [user?.id]);

  useEffect(() => {
    fetchUsers();
  }, [myPermissions]);

  const values = useMemo(() => ({
    adminUsers: adminUsers,
    myPermissions: myPermissions,
    refresh: refresh,
    fetchMyPermissions,
    initialPermissionsLoaded
  }), [adminUsers, fetchMyPermissions, initialPermissionsLoaded, myPermissions, refresh]);

  return <AdminUsersContext.Provider value={values}>{children}</AdminUsersContext.Provider>;
}

export function useAdminUsers() {
  const context = React.useContext<AdminUsersProviderProps>(AdminUsersContext);
  if (context === undefined) {
    throw new Error("useAdminUsers must be used within an AdminUsersProvider");
  }
  return context;
}
