import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/left-arrow.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as HiddenIcon } from "../../../assets/icons/hidden.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";

import UIPopover from "../../../components/Popover";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { EmptyStates } from "../../../components/EmptyStates";
import useProviders from "../hooks/useProviders";
import { NewRequestList } from "./NewRequestList";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../services/api";
import { BlockedProviderList } from "./BlockedProviderList";
import { ProviderProfile } from "../profile/ProfileView";
import { ProviderDeleteConfirm } from "../../dashboard/components/ProviderDeleteConfirm";
import { BlockProviderConfirm } from "../components/BlockProviderConfirm";
import { Button } from "../../../components/Button";
import { AddNewProvider } from "../profile/AddNewProvider";
import { ImageView } from "../../../components/Image";
import { buildURI } from "../../../services/csv-generator";
import { ShowToast } from "../../../services/toast";
import { Loading } from "../../../components/Loading/Loading";
import { SelectInput } from "../../../components/SelectInput";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";
import { LoginAsConfirm } from "../components/LoginAsConfirm";

function ProviderListLoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-2.5">
                  <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
                  <div className="w-28 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="subscription">
            <div className="w-24 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function ProviderList() {
  const { myPermissions } = useAdminUsers()
  const {
    providers,
    total,
    isLoading,
    loadMore,
    refetch: refetchProviders,
  } = useProviders();

  const { data: providersData, refetch: refreshCount } = useQuery({
    queryKey: ["provider-requests-count"],
    queryFn: () => {
      if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_PROVIDERS)) {
        return api.getUsers({
          type: "P",
          page: 0,
          limit: 10_000,
          showPending: true,
          showActive: false,
        })
      }
    },
  });

  const [userView, setUserView] = useState<any>(null);
  const [showNewRequestList, setShowNewRequestList] = useState(false);
  const [showBlockList, setShowBlockList] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState<any>(null);
  const [blockConfirmModal, setBlockConfirmModal] = useState<any>(null);
  const [newProviderModal, setNewProviderModal] = useState(false);
  const [loginAsConfirmModal, setLoginAsConfirmModal] = useState<{ firstName: string, lastName: string; id: string } | undefined>(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [filters, updateFilters] = useState<any>({});
  const [stateFilter, setStateFilter] = useState('')
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)


  const filteredProviders = providers?.filter((provider) => {
    if (filters.keyword) {
      return (
        provider.mobile?.toLowerCase().includes(filters.keyword.toLowerCase().trim()) ||
        provider.email?.toLowerCase().includes(filters.keyword.toLowerCase().trim()) ||
        provider.firstName?.toLowerCase().includes(filters.keyword.toLowerCase().trim()) ||
        provider.lastName?.toLowerCase().includes(filters.keyword.toLowerCase().trim())
      );
    }
    return true;
  });

  filteredProviders.sort((a, b) => {
    if (sortField === 'name') {
      const nameA = `${a.firstName} ${a.lastName}`;
      const nameB = `${b.firstName} ${b.lastName}`;
      return nameA.localeCompare(nameB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'mobile' && a.mobile && b.mobile) {
      return (sortASC ? (a.mobile < b.mobile) : (b.mobile < a.mobile)) ? -1 : 1;
    }
    if (sortField === 'email' && a.email && b.email) {
      return (sortASC ? (a.email < b.email) : (b.email < a.email)) ? -1 : 1;
    }
    if (sortField === 'status') {
      if (a.isAvailable && !b.isAvailable) return sortASC ? -1 : 1;
      if (!a.isAvailable && b.isAvailable) return sortASC ? 1 : -1;
      return 0;
    }

    if (sortField === 'city') {
      const cityA = zipCodes[a.addressZip]?.city;
      const cityB = zipCodes[b.addressZip]?.city;
      if (cityA && !cityB) return -1;
      if (cityB && !cityA) return 1;
      if (!cityA && !cityB) return 0;
      return cityA.localeCompare(cityB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'state') {
      const stateA = zipCodes[a.addressZip]?.state;
      const stateB = zipCodes[b.addressZip]?.state;
      if (stateA && !stateB) return -1;
      if (stateB && !stateA) return 1;
      if (!stateA && !stateB) return 0;
      return stateA.localeCompare(stateB) * (sortASC ? 1 : -1)
    }

    if ((sortField === 'joinedOn') && a.joinedOn && b.joinedOn) {
      return (sortASC ? (a.joinedOn < b.joinedOn) : (b.joinedOn < a.joinedOn)) ? -1 : 1;
    }

    if ((sortField === 'lastActive')) {
      const lastActiveA = a.lastActive;
      const lastActiveB = b.lastActive;
      if (lastActiveA && !lastActiveB) return -1;
      if (lastActiveB && !lastActiveA) return 1;
      if (!lastActiveA && !lastActiveB) return 0;

      return (sortASC ? (lastActiveA < lastActiveB) : (lastActiveB < lastActiveA)) ? -1 : 1;
    }

    if ((!sortField || sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }

    return 0;
  })

  const headers = [
    { value: "name", label: "Name" },
    { value: "mobile", label: "Phone Number" },
    { value: "email", label: "Email" },
    { value: "status", label: "Status" },
    { value: "city", label: "City" },
    { value: "state", label: "State" },
    { value: "joinedOn", label: "Joined On" },
    { value: "lastActive", label: "Last Active" },
  ]

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortASC(true)
        setSortField(undefined)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();
  const handleSort = (sort_by: string) => { };

  const handleNewProviderModal = () => {
    setNewProviderModal(true);
  };

  const closeNewProviderModal = (shouldRefresh?: boolean) => {
    if (shouldRefresh) refetchProviders();
    setNewProviderModal(false);
  };

  const fetchUsers = async () => {
    if (!myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_PROVIDERS)) {
      return;
    }
    try {
      setIsDownloading(true);
      const response = await api.getUsers({
        ...filters,
        type: "P",
        page: 0,
        limit: 1000,
      });
      const data = response?.items?.map((user) => {
        return [
          `${user.firstName} ${user.lastName}`,
          user.mobile,
          user.email,
          user.isAvailable ? "Accepting New Clients" : "Unavailable",
          zipCodes[user.addressZip]?.city ?? '-',
          zipCodes[user.addressZip]?.state_short ?? "-",
          moment.utc(user.joinedOn).local().format("MMM DD,YYYY"),
          user.lastActive ? moment.utc(user.lastActive).local().fromNow() : "-",
          user.addressState,
        ];
      });
      data.splice(0, 0, [
        "Name",
        "Phone Number",
        "Email",
        "Status",
        "City",
        "State",
        "Joined On",
        "Last Active",
        "Location",
      ]);
      const build = buildURI(data, true, undefined, undefined, undefined);
      // download csv file
      const a = document.createElement("a");
      a.href = build;
      a.download = `providers-${moment().format()}.csv`;
      a.click();
      // remove the a tag
      a.remove();
      setIsDownloading(false);
    } catch (error: any) {
      setIsDownloading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const allUserStateData = filteredProviders?.map((user) =>
  ({
    value: zipCodes[user.addressZip]?.state,
    label: zipCodes[user.addressZip]?.state
  }));
  const statesShown = allUserStateData?.filter((state, index) => state.value && allUserStateData.findIndex(s => s.value === state.value) === index);


  return (
    <>
      <LoadAnimatedContainer>
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <div className="text-black text-3xl font-bold">Manage Providers</div>
          <div className="flex justify-center">
            {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_PROVIDERS) && (
              <Button
                variant="primary"
                onClick={handleNewProviderModal}
                className="!rounded-full"
              >
                <PlusIcon className="[&_path]:stroke-white" />
                Add Provider
              </Button>
            )}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
              <SelectInput
                placeholder="Filter by state"
                disabledOptionText="Filter by state"
                data={stateFilter === '' ? statesShown : [{ label: "No Filter", value: '' }, ...statesShown]}
                value={stateFilter ?? ''}
                onChange={(e) => setStateFilter(e.target.value)}
                className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
              />
            </div>

            <div className="flex gap-2 items-center">
              <Button
                variant="link"
                onClick={() => fetchUsers()}
                disabled={isDownloading}
                className="border rounded-md !px-[14px] !py-2 border-primary hover:opacity-80 [&>svg]:!w-6 [&>svg]:!h-6 [&>svg]:!mr-0"
              >
                {isDownloading ? <Loading /> : <DownloadIcon />}
              </Button>
              <button
                onClick={() => setShowNewRequestList(true)}
                className="pl-4 pr-3.5 py-3 bg-[#E1F9F1] rounded-full justify-start items-center flex"
              >
                <div className="text-black text-base hover:text-black/80">
                  New Requests{" "}
                  {providersData ? `(${providersData?.totalRecords})` : ""}
                </div>
                <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
              </button>
              <button
                onClick={() => setShowBlockList(true)}
                className="pl-4 pr-3.5 py-3 group rounded-full justify-start items-center flex"
              >
                <div className="text-black text-base group-hover:text-black/80">
                  Deactivated Providers
                </div>
                <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
              </button>
            </div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />

                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />

                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer text-nowrap" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {filteredProviders?.filter(user => !stateFilter || zipCodes[user.addressZip]?.state === stateFilter)?.map((user) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]  [&_td]:text-wrap"
                    onClick={() => setUserView(user)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-2.5">
                        <div className="w-10 h-10">
                          {user.profileImg ? (
                            <ImageView
                              alt="user"
                              src={user.profileImg}
                              className="w-full h-full rounded-full object-cover"
                              loading="lazy"
                            />
                          ) : (
                            <div
                              className={`uppercase w-10 h-10 rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                            >
                              {user?.firstName?.[0] || ""}
                            </div>
                          )}
                        </div>
                        <h5 className="flex-1">
                          {user?.firstName} {user?.lastName} {user?.isHidden && <HiddenIcon className="inline-block w-4 h-4" />}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        {user.mobile}
                      </div>
                    </td>
                    <td className="account-manager">{user?.email}</td>
                    <td className="account-executive">
                      {user.isAvailable ? (
                        <div className="w-fit pl-2.5 pr-3 py-1.5 bg-white rounded-full border border-primary border-opacity-25 justify-start items-center gap-0.5 flex">
                          <CheckIcon className="w-4 h-4" />
                          <div className="text-black text-xs font-normal font-['Outfit'] leading-none">
                            Accepting New Clients
                          </div>
                        </div>
                      ) : (
                        <div className="w-fit pl-2.5 pr-3 py-1.5 bg-white rounded-3xl border border-neutral-400 border-opacity-25 justify-start items-center gap-0.5 inline-flex">
                          <CloseIcon className="w-4 h-4 [&_path]:stroke-[#7B7B7B]" />
                          <div className="opacity-60 text-black text-xs font-normal font-['Outfit'] leading-none">
                            Unavailable
                          </div>
                        </div>
                      )}
                    </td>
                    <td>
                      {zipCodes[user.addressZip]?.city ?? '-'}
                    </td>
                    <td>
                      {zipCodes[user.addressZip]?.state_short ?? "-"}
                    </td>
                    <td className="mrr">
                      {moment.utc(user.joinedOn).local().format("MMM DD,YYYY")}
                    </td>

                    <td className="mrr">
                      {user.lastActive
                        ? moment.utc(user.lastActive).local().fromNow()
                        : "-"}
                    </td>

                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <ProviderOptionsDropdown
                            onBlock={() => {
                              close();
                              setBlockConfirmModal(user);
                            }}
                            onView={() => {
                              close();
                              setUserView(user);
                            }}
                            isUserHidden={user.isHidden}
                            onToggleHide={async () => {
                              close();
                              const response = await api.saveUserProfile(user?.id, { isHidden: !user?.isHidden });
                              if ([200, 201].includes(response.status)) {
                                ShowToast({
                                  type: "success",
                                  message: `User ${user?.isHidden ? "Unhidden" : "Hidden"}!`,
                                });
                                refetchProviders(undefined, undefined, true);
                              }
                            }}
                            onRemove={() => {
                              close();
                              setRemoveConfirmModal(user);
                            }}
                            onLoginAsProvider={async () => {
                              close();
                              setLoginAsConfirmModal(user)
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {isLoading && <ProviderListLoadingSkeleton />}
                <div className="h-5"></div>
              </tbody>
            </table>
            {isLoading === false && !providers?.length && (
              <EmptyStates text="No providers found!" />
            )}
          </div>
        </div>
        {showNewRequestList && (
          <NewRequestList
            isOpen={showNewRequestList}
            onClose={() => setShowNewRequestList(false)}
            onRefresh={() => {
              refreshCount();
              refetchProviders(undefined, undefined, true);
            }}
          />
        )}
        {showBlockList && (
          <BlockedProviderList
            isOpen={showBlockList}
            onClose={() => setShowBlockList(false)}
            onRefresh={() => refetchProviders(undefined, undefined, true)}
          />
        )}
        {userView && (
          <ProviderProfile
            isOpen={!!userView}
            provider={userView}
            onClose={() => setUserView(null)}
            onRefresh={refetchProviders}
            onEditUpdate={(updatedData) => setUserView(updatedData)}
          />
        )}
        {removeConfirmModal && (
          <ProviderDeleteConfirm
            onCancel={() => setRemoveConfirmModal(null)}
            onSuccess={() => {
              refetchProviders(removeConfirmModal.id, true);
              setRemoveConfirmModal(null);
            }}
            user={removeConfirmModal}
          />
        )}
        {blockConfirmModal && (
          <BlockProviderConfirm
            onCancel={() => setBlockConfirmModal(null)}
            onSuccess={() => {
              refetchProviders(blockConfirmModal.id, true);
              setBlockConfirmModal(null);
            }}
            user={blockConfirmModal}
          />
        )}
        {newProviderModal && (
          <AddNewProvider
            isOpen={newProviderModal}
            onClose={closeNewProviderModal}
          />
        )}
        {loginAsConfirmModal && (
          <LoginAsConfirm
            user={loginAsConfirmModal}
            onCancel={() => setLoginAsConfirmModal(undefined)}
            onLoginAs={async () => {
              const loginData = await api.adminLoginAsProvider(loginAsConfirmModal.id)
              window.open(loginData.url, "_blank", "noreferrer");
              setLoginAsConfirmModal(undefined);
            }
            } />
        )}
      </LoadAnimatedContainer>
    </>
  );
}

export const ProviderOptionsDropdown = ({ onView, onBlock, onRemove, isUserHidden, onToggleHide, onLoginAsProvider }) => {
  const { myPermissions } = useAdminUsers()
  return (
    <div className="z-10 w-[180px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onView();
            }}
          >
            View Profile
          </button>
        </li>
        {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_PROVIDERS) && <>
          <li>
            <button
              className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover"
              onClick={(e) => {
                e.stopPropagation();
                onToggleHide();
              }}
            >
              {isUserHidden ? "Unhide" : "Hide"} Profile
            </button>
          </li>
          <li>
            <button
              className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover"
              onClick={(e) => {
                e.stopPropagation();
                onBlock();
              }}
            >
              Deactivate
            </button>
          </li>
        </>}
        {myPermissions.includes(AdminPermission.PROVIDER_ACCOUNT_ACCESS) && (
          <li>
            <button
              className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover"
              onClick={(e) => {
                e.stopPropagation();
                onLoginAsProvider();
              }}
            >
              Login as provider
            </button>
          </li>
        )}

      </ul>
    </div>
  );
};
